.field-select-dropdown__custom.edh-dropdown-button {
  max-width: unset;
  border: 1px solid var(--gray-color3);
  .dropdown-toggle {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;
    max-width: unset;
    color: #c8c3d4;
    font-weight: 400;
    font-size: 14px;
    height: 38px;

    &::after {
      color: var(--placeholder-color);
    }
  }

  .dropdown-menu {
    min-width: 200px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: none;
    padding: 24px 24px 20px 24px;
    z-index: var(--zindex-popover);
  }

  .dropdown-item,
  .dropdown-item:first-child {
    padding: 0 0 16px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--black-3-color);
    letter-spacing: 0;
    text-transform: unset;
    pointer-events: unset;
    &:last-child {
      padding: 0;
    }
  }
}
