.tooltip {
  .tooltip-inner {
    max-width: 280px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    padding: 8px 12px;
  }
  z-index: 10800 !important;
}

.am-tooltip {
  position: absolute;
  .tooltip-arrow::before {
    position: absolute;
    content: '';
    border-color: transparent;
    border-style: solid;
  }
  .am-tooltip-inner {
    max-width: 280px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    padding: 8px 12px;
    color: #fff;
    background-color: #000;
    border-radius: 0.25rem;
  }
  &[x-placement='top'] {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    .tooltip-arrow {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &[x-placement='bottom'] {
    left: 50%;
    transform: translateX(-50%);
    .tooltip-arrow {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &[x-placement='left'],
  &[x-placement='right'] {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    .tooltip-arrow {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &[x-placement='right'] {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    .tooltip-arrow {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
