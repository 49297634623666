.lines-ellipsis-button {
  padding-left: 1px;
  color: var(--first-color);
  background-color: transparent;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}

#multi-clamp-button {
  padding-left: 1px;
  border: none;

  color: var(--first-color);
  background-color: none;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}
