.edh-error-forbidden-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--body-color);

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid var(--gray-color7);
    background: var(--base-white);

    &-logout-btn {
      position: absolute;
      right: 1.5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 65%;
    padding-top: 60px;

    &:has(.edh-error-forbidden-page__content__alert) {
      padding-top: 30px;
    }

    &--image {
      margin-bottom: 33px;
    }
    &--message {
      color: var(--neutral-n800);
      font-size: 36px;
      font-weight: 700;
      line-height: 56px;
    }
    &--description {
      margin: 16px 0 33px 0;
      text-align: center;

      color: var(--neutral-n700);
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.1px;
    }
    &__alert {
      margin-bottom: 40px;
    }

    .btn-custom-primary {
      padding: 12px 16px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
