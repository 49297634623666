@import 'assets/styles/mixin/responsive.scss';

.am-tab-availability {
  position: relative;
  min-height: 500px;
  .pagination {
    background-color: var(--white-color);
  }
  &__model {
    border-radius: 8px;
    padding: 24px 24px 94px;
    background-color: var(--body-color);

    &__title {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 18px;
      color: var(--black-3-color);
    }
    &__list {
      height: fit-content;
      max-height: 800px;
      overflow-y: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      @include responsive-mobile {
        max-height: fit-content;
      }
      .am-data-extract {
        width: calc(33% - 4px);
        background-color: var(--white-color);
        @include responsive-mobile {
          width: 100%;
          padding: 16px 14px;
          height: 100%;
          background-color: var(--body-color);
          border: 1px solid var(--neutral-n200);
          border-radius: 8px;
          &__title {
            font-size: 14px;
            margin-bottom: 8px;
          }
          &__description {
            margin-bottom: 8px;
            height: unset;
            -webkit-line-clamp: 2;
          }
        }
        @include responsive-13inch {
          width: 100%;
          height: unset;
          padding: 16px 14px;
          &__title {
            font-size: 14px;
            margin-bottom: 8px;
          }
          &__description {
            margin-bottom: 8px;
            height: unset;
            -webkit-line-clamp: 2;
          }
        }
      }
      .edh-message-no-data {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
    .center {
      justify-content: center;
    }
  }
  @include responsive-mobile {
    &__model {
      padding: 14px 0;
      &__title {
        margin-bottom: 14px;
        font-size: 14px;
      }
    }
  }

  & .edh-pagination {
    @include responsive-13inch {
      display: block;

      &__right {
        margin-top: 16px;
      }
    }
  }
}
