.edh-cell-actions {
  position: relative;

  &__pending-wrap {
    position: relative;
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn-three-dots {
    padding: 0rem 0.5rem;

    &:focus {
      box-shadow: none;
    }

    &.no-action {
      cursor: default;
    }
  }

  &__menus {
    min-width: 200px;
    width: fit-content;
    padding: 1.5rem;
    background: var(--container-color);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    z-index: var(--zindex-popover);
    cursor: pointer;
  }

  &__menu-item {
    margin-top: 1rem;
    text-align: left;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.325s;
    &.disabled {
      cursor: not-allowed;
      > span {
        pointer-events: none;
      }
    }
    &:first-child {
      margin-top: 0rem;
    }

    &:hover:not(.disabled) {
      font-weight: 600;
      color: var(--first-color);
    }
  }
}
