.am-preview-subtab__model-body.preview-model-text-to-image {
  display: flex;
  flex-direction: column;
  .am-preview-subtab__model-panel {
    .sample-data {
      &__form-container,
      &__image-container {
        border-radius: 8px;
        background: var(--general-white, #fff);
        box-shadow: 0px 0px 1px 0px #747474;
        padding: 16px 24px;
      }
      &__image-container {
        display: block;
      }
      &__image {
        border-radius: 8px;
        max-height: 363px;
        display: block;
        margin: 0 auto;
      }
      &__label {
        color: #000;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        margin-bottom: 8px;
      }
    }
  }
}
