$theme-colors: (
  'custom-primary': #00a19c,
  'custom-out-primary': #ffffff,
  'custom-secondary': #ffffff,
  'custom-danger': #e82121,
  'custom-default': #e7e7f0,
  'custom-cancel': #ffffff,
);

/* Custom classes */
.btn {
  &.btn-primary:focus,
  &.btn-link:focus {
    outline: 0;
    box-shadow: none;
  }

  &:disabled {
    pointer-events: auto !important;
    cursor: not-allowed !important;
  }

  &.btn-custom-primary,
  &.btn-custom-out-primary {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  &.btn-custom-primary,
  &.btn-custom-primary:hover,
  &.btn-custom-primary:focus,
  &.btn-custom-danger,
  &.btn-custom-danger:hover,
  &.btn-custom-danger:focus {
    color: #ffffff;
    font-weight: 500;
  }

  &.btn-custom-out-primary {
    border: 1px solid #dcdce6;
    box-shadow: 0px 1px 3px rgba(63, 60, 76, 0.2);
    color: #00a19c;

    &:hover,
    &:focus {
      background-color: #00a19c;
      border-color: #00a19c;
      color: #ffffff;
    }
  }

  &.btn-custom-cancel {
    border: 1px solid #dcdce6;
    color: #9f9eae;
    font-weight: 500;
    background-color: #ffffff;
    margin-right: 0.5rem !important;

    &:hover,
    &:focus {
      border: 1px solid #dcdce6;
      color: #9f9eae;
    }
  }

  &.btn-custom-primary:disabled,
  &.btn-custom-primary.disabled,
  &.btn-custom-danger:disabled,
  &.btn-custom-danger.disabled,
  &.btn-custom-cancel:disabled,
  &.btn-custom-cancel.disabled,
  &.btn-custom-default:disabled,
  &.btn-custom-default.disabled {
    color: #9f9eae;
    background-color: #e7e7f0;
    border-color: #e7e7f0;
  }
}

.pagination {
  a[role='button']:focus {
    box-shadow: none;
  }
}
