@import 'assets/styles/mixin/responsive.scss';

.edh-banner {
  position: relative;
  height: 346px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include responsive-mobile {
    height: 360px;
  }

  &__vector {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  & .edh-banner-info {
    text-align: center;
  }

  &-info__page-title {
    font-family: 'MuseoSans900';
    display: inline-block;
    position: relative;
    font-size: 65px;
    line-height: 1.1;
    letter-spacing: 6px;
    color: #ffffff;
    text-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  }

  &-info__description {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.25px;
    color: #ffffff;
    text-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;

    &.dm {
      max-width: 825px;
    }

    @include responsive-mobile {
      padding: 0 40px;
    }

    @include responsive-13inch {
      padding: 0 136px;
    }
  }

  &-info__logo {
    width: 305px;
    height: 123px;

    @include responsive-mobile {
      width: 228px;
      height: 92px;
    }
  }
  &-analytics-marketplace {
    @include responsive-mobile {
      .edh-banner-info {
        &__page-title {
          font-family: 'MuseoSans900';
          font-size: 24px;
          line-height: 40px;
          letter-spacing: 0.25px;
        }
        &__description {
          margin-top: 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  &-data-marketplace {
    @include responsive-mobile {
      padding-top: 0.5rem;

      .edh-banner-info {
        &__page-title {
          font-size: 24px;
          line-height: 40px;
          letter-spacing: 0.25px;
        }

        &__description {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          padding: 0.5rem 1.5rem 0;
          margin-top: 0;
        }
      }
    }
  }
}
