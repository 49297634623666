@import 'assets/styles/mixin/responsive.scss';

.am-export-modal {
  .modal-dialog {
    max-width: 550px;
  }

  & .modal-content {
    padding: 24px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 11px;
    font-weight: 700;
    line-height: 13.2px;
    letter-spacing: 1px;
    color: var(--first-color);
    text-transform: uppercase;
  }

  &__close {
    border: none;
    background-color: transparent;

    svg {
      color: #9f9eae;
    }
  }

  &__name {
    margin-top: 6px;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: var(--black-color);
  }

  &__choose {
    margin: 16px 0;
    font-size: 14px;
    line-height: 22px;

    &::after {
      content: ' *';
      color: #e82121;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 193px;
    width: 100%;
    row-gap: 16px;
  }

  &__type {
    width: 50%;
    input {
      position: absolute;
      opacity: 0;
      &:checked {
        & ~ span::after {
          display: block;
          background-color: var(--first-color);
        }
      }
    }

    label {
      position: relative;
      padding-left: 32px;
      min-width: 193px;
      font-size: 15px;
      line-height: 24px;
      cursor: pointer;

      &:hover input ~ span {
        background-color: #dcdce6;
      }

      p {
        display: inline-block;
        font-weight: 500;
      }
    }
    @include responsive-mobile {
      label {
        display: flex;
        flex-direction: column;
        span {
          top: 6px;
        }
      }
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background: var(--white-color);
      border: 2px solid #dcdce6;
      border-radius: 24px;

      &::after {
        content: '';
        position: absolute;
        display: none;
        top: 5px;
        left: 5px;
        border-radius: 10px;
        width: 10px;
        height: 10px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;
    margin-top: 26px;

    button {
      border-radius: 4px;
      padding: 8px 20px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__cancel {
    border: 1px solid #dcdce6;
    color: #9f9eae;
    background-color: var(--white-color);
  }

  &__download {
    border: none;
    color: var(--white-color);
    background-color: var(--first-color);
  }
}
