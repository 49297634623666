.edh-user-guide-question {
  padding: 12px 0 12px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--neutral-n200);
  }

  &.expand h3 svg {
    transform: rotate(-180deg);
  }

  h3 {
    display: flex;
    align-items: center;

    color: var(--first-color);

    font-weight: 700;
    font-size: 11px;
    line-height: 120%;

    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;

    svg {
      width: 24px;
      height: 24px;
      padding: 6px;
      transition: all linear 0.25s;
    }
  }

  &__list {
    width: 100%;
    height: 100%;
    max-height: 0px;
    padding: 0 8px;

    transition: all ease-in-out 0.25s;
    overflow: hidden;
  }

  &.expand &__list {
    max-height: 160px;
    padding: 8px 8px 0;
    overflow-y: auto;
  }

  &__item {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__item-question,
  &__item-answer,
  p {
    width: 100%;

    font-size: 14px;
    line-height: 20px;

    color: var(--black-3-color);
  }

  &__item-question {
    font-weight: 700;
  }

  &__item-answer,
  p {
    padding: 0 14px;
    font-weight: 400;
  }

  &__item-answer {
    a {
      padding: 0 4px;
      color: var(--blue-color-2);
      text-decoration: underline;
    }
  }
}
