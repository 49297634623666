.edu-history-export-item {
  background: var(--container-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  padding: 19px;
  margin-bottom: 0.5rem;

  &__status {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .edh-download-status__name {
      font-size: 14px;
    }
  }

  &__name {
    width: fit-content;
    max-width: 100%;
  }

  &__name p {
    font-size: 13px;
    font-weight: bold;
    color: #6f6f6f;
    margin: 0.5rem 0rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__date {
    display: flex;
    align-items: center;
    & > div {
      margin-right: 8px;
    }
  }

  &__type {
    font-weight: 500;
    font-size: 10px;
    color: #1c3b92;
    padding: 2.5px 4px;
    background: #e4e8f3;
    border-radius: 2px;
  }
}
