@import 'assets/styles/mixin/responsive.scss';

.edh-react-table {
  $this: &;

  position: relative;
  width: 100%;
  font-size: 12px;
  color: var(--title-color);

  .th,
  .td {
    padding: 1rem 0.5rem;
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;

    &:last-child {
      border-right: 0;
    }
  }

  &__no-data.td {
    border-bottom: 0;
    background-color: #fff !important;
  }

  &__table {
    overflow: auto;
  }

  &__table,
  &__table-header,
  &__table-body {
    width: 100%;
  }

  &__table-header {
    .tr {
      .th {
        background: #f7fbfc;
        color: #00a19c;
        font-weight: 600;
        font-size: 13px;
        border-right: 1px solid #e7e7f0;
        border-bottom: 1px solid #e7e7f0;
        border-top: 1px solid #e7e7f0;
        height: 48px;

        &:last-child {
          border-right: none;
        }

        .cell,
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
        }
      }
    }
  }

  &__table-body {
    .edh-message-no-data {
      text-align: center;
      margin: 32px 0 0;
      padding-bottom: 32px;
    }

    .tr {
      .active-cell {
        border: 2px solid var(--green-color6);
      }
      .disabled {
        pointer-events: none;
      }
      .td {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 120%;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #2d2b39;
        box-sizing: border-box;
        border: 2px solid transparent;

        .cell {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          word-break: break-word;

          .edh-cell-actions {
            margin: auto;
          }

          .edh-click-here-to-view.btn {
            color: var(--first-color);
            font-size: 13px;
            font-weight: 500;
            line-height: 1.2;

            cursor: pointer;
            padding: unset;
          }
        }
      }

      &:hover {
        .td {
          background-color: #f0ecfe;
        }
      }

      &.active {
        .td {
          background-color: #fffaef;
        }
      }

      &.expanded {
        .td {
          background-color: var(--first-color);
          color: var(--container-color);
        }
      }

      &.has-expand {
        .td {
          padding: 0;
        }

        .cell {
          padding: 1rem 0.5rem;
        }
      }
    }
  }

  &--striped {
    #{$this}__table-body {
      .tr:nth-of-type(odd) > * {
        background-color: #fafafa;
      }
    }
  }

  &--sticky {
    #{$this}__table {
      overflow: auto;

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }

    #{$this}__table-header,
    #{$this}__table-body {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    #{$this}__table-header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }

    #{$this}__table-body {
      position: relative;
      z-index: 0;
    }
  }

  #{$this}__col-actions-header {
    padding: 10px;
  }

  .no-data {
    position: sticky;
    left: 0;
  }

  @include responsive-mobile {
    .edh-react-table__col-actions-header {
      padding: 5px;

      img {
        max-width: unset;
      }
    }
  }
}
