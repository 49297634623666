.edh-select {
  $this: &;

  width: 100%;
  & &--hide-menu .edh-select__input-container {
    cursor: text;
  }
  & &__indicator {
    svg {
      cursor: pointer;
    }
  }

  & &__placeholder {
    font-size: 14px;
    color: var(--gray-color);
  }

  & &__input {
    font-size: 14px !important;
    opacity: 1 !important;
  }

  & &__single-value {
    font-size: 14px;
  }

  & &__multi-value {
    border-radius: 16px;
    padding: 0.125rem 0.75rem;
    background-color: var(--container-color);
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
    border: 1px solid #dcdce6;

    &__label {
      font-size: 12px;
      color: var(--text-color);
    }
  }

  & &__control {
    border-color: #c4c3d1;
    box-shadow: none;
    &:hover {
      border-color: #c4c3d1;
    }
  }

  & .edh-keywords-suggest-option {
    cursor: pointer;
  }

  & &__option {
    font-size: 15px;
    color: var(--black-3-color);
    cursor: pointer;

    &--is-selected,
    &--is-focused {
      background-color: #eeeef4;
    }
    &--is-selected {
      color: var(--first-color);
      font-weight: 700;
    }
  }
  & &__menu {
    border-radius: 10px;
    z-index: var(--zindex-popover);
  }

  & &--hide-menu.edh-select__menu {
    display: none;
  }

  &__no-options {
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color-light);
  }

  &--max-length,
  &--up-to-numbers-datasets {
    position: absolute;
    right: 0;
    bottom: -17px;
    font-size: 0.688rem;
    line-height: 1.2;
    color: #9e9e9e;
    text-transform: lowercase;
  }

  &--up-to-numbers-datasets {
    text-transform: none;
  }

  & &__value-container--is-multi > div {
    max-width: fit-content;
  }
}
#react-select-9-listbox {
  max-height: 261px;
}
