.am-preview-subtab {
  &__model-content {
    .sample-data__label {
      color: black;
      font-size: 12px;
      font-weight: 700;
    }

    .sample-data__title {
      color: black;
      font-size: 12px;
    }

    .sample-data__title-right {
      color: black;
      font-size: 12px;
      float: right;
    }

    .sample-data__progress {
      height: 12px;
      width: 100%;
      background-color: #e0e0de;
      border-radius: 50px;
      margin-top: 2px;
    }

    .sample-data__filler {
      height: 100%;
      width: 0;
      background: linear-gradient(
        95.52deg,
        rgb(0, 161, 156) -3.5%,
        rgb(118, 63, 152) 99.17%
      );
      border-radius: 50px;
    }
  }
  .sample-data__text {
    resize: none;
    width: 100%;
    background: var(--white-color);
    border: 8px solid transparent;
    border-right: none;
    outline: 1px solid var(--gray-color3);
    border-radius: 4px;
    font-size: 14px;
    line-height: 140%;
    color: var(--black-3-color);
    box-sizing: border-box;
    overflow-y: scroll;
    ~ button {
      display: flex;
      margin-left: auto;
      align-items: center;
      cursor: not-allowed;
      &:not(:disabled) {
        cursor: pointer;
        background: var(--first-color);
        p {
          color: var(--white-color);
        }
        svg {
          path {
            fill: var(--white-color);
          }
        }
      }
      p {
        margin-left: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: var(--gray-color);
      }
    }
    span {
      white-space: pre-line;
      font-weight: 400;
      color: var(--neutral-n900, #2d2b39);
      font-size: 14px;
      line-height: 140%;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
    }
  }

  .output-result {
    height: 156px;
    overflow-y: scroll;
    background: var(--neutral-n200, #e7e7f0);
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
    }
  }
}
