.am-data-extract {
  width: 293px;
  height: 190px;
  background: var(--white-color);
  box-shadow: 0px 0px 1px var(--primary-n0);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--black-color);
    margin-bottom: 19px;
    width: fit-content;
    max-width: 96%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--black-3-color);
    margin-bottom: 22px;
    width: fit-content;
    max-width: 96%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 51px;
  }
  &__link {
    color: var(--first-color) !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding-left: 0px !important;
    :hover {
      color: var(--first-color) !important;
    }
  }
  &__icon {
    margin-left: 5px;
    margin-bottom: 2px;
  }
}
