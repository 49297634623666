@import 'assets/styles/mixin/responsive.scss';

.edh-export-history {
  position: relative;

  &__btn.btn {
    @include responsive-mobile {
      padding: 0 5px;
    }
  }

  &__popover {
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0px;
    width: 418px;
    height: auto;
    background: var(--container-color);
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    z-index: var(--zindex-popover);

    .edh-message-no-data {
      margin: 80px 0;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 92%;
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent var(--container-color) transparent;
    }
  }

  &__title {
    padding: 1.5rem;
    border-bottom: 1px solid #e7e7f0;

    h6 {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }
  }

  &__items {
    padding: 1rem 1.5rem;
  }

  &__action {
    padding: 0.5rem 0.75rem;
    border-top: 1px solid #e7e7f0;

    .btn {
      color: #00a19c;
      font-weight: bold;
      font-size: 12px;
    }
  }
}
