@import 'assets/styles/mixin/responsive.scss';

.am-tab-technical {
  background-color: var(--body-color);
  &__body {
    border-radius: 8px;
    padding: 24px 24px 94px;
    background-color: var(--body-color);
    overflow-y: auto;
  }
  &__title {
    margin-bottom: 8px;

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: var(--black-3-color);
    }
  }

  &__description {
    margin-bottom: 20px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: var(--black-3-color);
    }
  }
  &__table {
    .edh-react-table__table {
      .edh-react-table__table-body {
        overflow: auto;
        max-height: 520px;
        .tr {
          border-bottom: 1px solid #eeeef4;
        }
      }
    }
    .edh-message-no-data {
      margin-top: 20px;
      margin-bottom: 150px;
    }
  }
  &__footer {
    margin-top: 20px;
    p {
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--neutral-n700);
    }
  }
  @include responsive-mobile {
    &__body {
      padding: 24px 0px;
      &--content {
        overflow: hidden;
        min-height: 300px;
      }
    }
    &__table {
      .edh-message-no-data {
        margin-bottom: 20px;
      }
    }
  }
}
