.edh-user-guide-group {
  &__heading {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 8px;

    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.25px;

      color: var(--black-3-color);
    }
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__content {
    padding: 0 32px;

    & > * {
      font-size: 16px;
    }
  }
}
