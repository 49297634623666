@import 'assets/styles/mixin/responsive.scss';

.reply {
  &__info {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
  &__card {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    background: transparent;
    &.bg {
      background: #ffffff;
      padding: 8px 8px;
    }
    .edh-avatar {
      flex: 0 0 32px;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    justify-content: center;
  }
  &__user {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--black-3-color);
    flex-grow: 1;
  }
  &__admin {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #00a19c;
    flex-grow: 1;
  }
  &__description {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--black-3-color);
    word-break: break-word;
    .mention {
      color: var(--blue-color-2);
    }
  }
  &__action {
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 8px;
    button {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #9e9e9e;
      padding: 0;
      &:first-child {
        order: 1;
      }
      &:last-child {
        order: 3;
      }
    }
    border: var(--black-3-color);

    &.vertical-divider {
      &::before {
        order: 2;
        content: '';
        border-right: 0.5px solid #9e9e9e;
        margin: 1px 0;
      }
    }
  }
  &__posting {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #9e9e9e;
  }
  @include responsive-mobile {
    margin-bottom: 0px;
    &__card {
      padding: 0;
      margin-bottom: 0px;
      .bg {
        padding: 14px 8px;
      }
    }
    &__content {
      margin-left: 8px;
      flex-direction: column;
    }
    &__description {
      margin-bottom: 4px;
    }
    &__action {
      display: flex;
      align-self: flex-start;
    }
  }
}
