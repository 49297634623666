@import 'assets/styles/mixin/responsive.scss';

.am-preview-subtab {
  padding: 30px 24px 24px;
  &__model-panel {
    flex: 1;
    min-width: 50%;
  }
  &__model-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
    .am-preview-subtab__model-title {
      margin-bottom: 0;
      white-space: nowrap;
    }
    @include responsive-mobile {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  }
  &__model-control {
    overflow: hidden;
    .sample-file-pagination {
      display: flex;
      gap: 16px;
      border-radius: 4px;
      border: 1px solid var(--border-color);
      background: var(--container-color);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
      justify-content: space-between;
      align-items: center;
      padding: 4px 0;
      &__label {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        white-space: nowrap;
        min-width: 0;
        width: 100%;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
      &__previous {
        background: var(--container-color);
        border: none;
        border-right: 1px solid var(--neutral-n200);
        padding-left: 15px;
        padding-right: 16px;
      }
      &__previous[disabled] {
        cursor: not-allowed;
      }
      &__next {
        border: none;
        border-left: 1px solid var(--neutral-n200);
        background: var(--container-color);
        padding-left: 16px;
        padding-right: 15px;
      }
      &__next[disabled] {
        cursor: not-allowed;
      }
    }
    @include responsive-mobile {
      width: 100%;
    }
  }
  &__model-title {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: var(--black-3-color);
    height: 34px;
    display: flex;
    align-items: center;
    @include responsive-mobile {
      margin-bottom: 8px;
    }
  }

  &__model-content {
    position: relative;
    box-shadow: 0px 0px 1px var(--primary-n0);
    border-radius: 8px;
    background-color: var(--white-color);
    height: 571px;

    &.preview-pdf {
      max-height: 571px;
      min-height: 571px;
      height: 100%;
      overflow: hidden;
    }

    &.preview-img {
      overflow: hidden;

      @include responsive-mobile {
        height: unset;
        min-height: 250px;
      }
    }

    &.preview-json {
      padding: 8px;
      overflow: scroll;
      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-corner {
        border-radius: 8px;
      }
      @include responsive-mobile {
        height: 388px;
        #formattedJson {
          min-width: calc(100vw - 100px);
        }
        &::-webkit-scrollbar {
          height: 4px;
          width: 4px;
          border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          background: var(--scroll-thumb-color);
        }
      }
    }

    &.preview-table {
      overflow: hidden;
      .edh-react-table {
        padding: 8px 0 54px 8px;
        &:only-child {
          padding-bottom: 0;
        }
        height: 100%;
        &__table {
          &::-webkit-scrollbar-corner {
            border-radius: 8px;
          }
          height: 100%;
          &-header {
            height: fit-content;
          }
          &-header,
          &-body {
            min-width: 100%;
          }
        }
      }

      @include responsive-mobile {
        .edh-react-table {
          padding: 8px 0 54px 8px;
        }
      }
    }

    & .output-pagination {
      display: flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      left: 10px;
      bottom: 14px;
      background: #fff;
      &__previous,
      &__next {
        display: block;
        border: none;
        border-radius: 4px;
        width: 36px;
        height: 36px;
        background: var(--first-color);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

        &:disabled {
          background: #c4c3d1;
          opacity: 0.2;
          cursor: not-allowed;
        }

        svg {
          display: block;
          margin: auto;
        }
      }

      &__previous {
        svg {
          transform: rotate(-180deg);
        }
      }

      &__input {
        border: 1px solid #c4c3d1;
        border-radius: 4px;
        width: 36px;
        height: 36px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
        font-size: 14px;
        line-height: 19.6px;
        color: var(--black-3-color);
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type='number'] {
          -moz-appearance: textfield;
        }
      }

      &__page {
        font-size: 14px;
        line-height: 22px;
        color: var(--black-6-color);
      }
    }
  }
}
