.edh-modal-title {
  &__category {
    color: var(--first-color);
    font-size: 11px;
    font-weight: bold;
    line-height: 120%;
    letter-spacing: 1px;
    text-transform: uppercase;
    word-break: break-word;
  }

  &__value {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-top: 6px;
    word-break: break-word;
  }
}
