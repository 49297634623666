.edh-table-call-text-editor {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  &__text,
  textarea {
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    color: var(--black-3-color);
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    min-height: 1rem;
  }

  textarea {
    background-color: transparent;
    width: 100%;
    border: none;
    resize: none;
  }

  .disabled {
    pointer-events: none;
  }
}
