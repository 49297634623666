:root {
  --with-sidebar: 80px;
  --full-with-sidebar: 240px;
  --height-sidebar-item: 56px;
  --height-topnavs: 54px;
  --height-topnavs-breadcrumb: 145px;
  --height-topnavs-breadcrumb-data-quality: 128px;

  --border-radius: 8px;

  /* Colors */
  --first-color: #00a19c;
  --first-color-light: #009089;
  --gray-color: #9f9eae;
  --gray-color2: #f6f6fa;
  --gray-color3: #c4c3d1;
  --gray-color4: #728384;
  --gray-color5: #fffcf5;
  --gray-color6: #757575;
  --gray-color7: #e0e0e0;
  --gray-color8: #e5e7eb;
  --gray-color9: #bfcdd1;
  --gray-color10: #f8f8f8;
  --gray-color11: #d8d8d8;
  --gray-color12: #e6e6e6;
  --gray-color13: #6e6e6e;
  --gray-color14: #a8a8a8;
  --gray-color15: #8d8d8d;
  --gray-color16: #fee9c6;
  --gray-color17: #9e9e9e;
  --gray-600: #393939;
  --black-color: #2d2b39;
  --black-2-color: #525252;
  --black-3-color: #3f3c4c;
  --black-4-color: #000000;
  --black-5-color: #212121;
  --black-6-color: #787587;
  --black-7-color: #262626;
  --black-8-color: #181818;
  --white-color: #ffffff;
  --base-white: #fff;
  --purple-color: #7365b1;
  --purple-color1: #6559aa;
  --green-color: #66b2c4;
  --green-color2: #308196;
  --green-color3: #d9f2f7;
  --green-color4: #758ca3;
  --green-color5: #edf1f8;
  --green-color6: #3685d3;
  --danger-color: #e82121;
  --reject-color: #f64d4b;
  --success-color: #9bd076;
  --green-tertiary: #16c0b7;
  --purple-p100: #dedaf5;
  --purple-p900: #342f5c;
  --yellow-y200: #fedc92;
  --yellow-2-color: #ffff00;
  --yellow-3-color: #fcaa1b;
  --yellow-4-color: #fef9f2;
  --yellow-5-color: #fdb924;
  --yellow-6-color: #e56e18;

  --title-color: #2d2d2d;
  --text-color: #424242;
  --text-color-2: #666;
  --text-color-light: #6f6f6f;
  --placeholder-color: #9f9eae;
  --body-color: #fafafa;
  --container-color: #ffffff;
  --scroll-thumb-color: #dfe1e3;
  --loading-color: #cccccc;
  --box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  --border-color: #dcdce6;
  --background-color: #f4f5f8;
  --surface-border-color: #c9d5e3;

  --light-gray-100: #f2f4f8;
  --light-primary-50: #f4fbfb;

  --neutral-n200: #e7e7f0;
  --neutral-n700: #625e70;
  --neutral-n800: #3f3c4c;
  --neutral-g200: #a3d4e1;
  --petronas-green3: #f7fbfc;
  --petronas-g500: #50a4b9;
  --petronas-g50: #f7fbfc;
  --emphasis-high-n900: #2d2b39; // --black-color: #2D2B39;
  --neutral-n100: #eeeef4;
  --blue-color-300: #637ab8;
  --blue-color-1: #4098ae;
  --blue-color-2: #0b69ff;
  --blue-color-3: #d3dee8;
  --blue-color-4: #0d6efd;
  --blue-color-5: #e0f3f3;
  --blue-color-6: #005cb9;
  --blue-grays-100: #d3dee8;
  --light-blue-color: #84a3c2;
  --brown-color-900: #65490e;
  --primary-n0: #747474;
  --primary-700: #007874;
  --primary-800: #00645c;
  --primary-900: #00483f;
  --purple-color: #5b3075;
  --purple-100-color: #ebe1f1;
  --previous-color-bg-text: #838e94;
  --gradient-green: linear-gradient(
    65deg,
    #009089 0%,
    #00a19c 36.15%,
    rgba(38, 175, 171, 0) 100%
  );
  --gradient-blue: linear-gradient(
    65deg,
    #00488f 0%,
    #005cb9 36.15%,
    rgba(225, 242, 254, 0) 100%
  );
  --gradient-purple: linear-gradient(
    65deg,
    #5b3075 0%,
    #8b58b1 36.15%,
    rgba(225, 242, 254, 0) 100%
  );

  /* Font and typography */
  --body-font: 'Inter';
  --biggest-font-size: 5rem;
  --bigger-font-size: 3.5rem;
  --big-font-size: 2.5rem;
  --intermediate-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  --very-small-font-size: 11px;

  /* Responsive typography */
  @media screen and (min-width: 968px) {
    --biggest-font-size: 7.5rem;
    --bigger-font-size: 4.5rem;
    --big-font-size: 4rem;
    --intermediate-font-size: 1.75rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
    --very-small-font-size: 0.75rem;
  }

  /* Margenes */
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;

  /* used to define z-index */
  --zindex-search-bar: 990;
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-offcanvas: 1050;
  --zindex-modal: 1060;
  --zindex-popover: 1070;
  --zindex-alert: 1075;
  --zindex-tooltip: 1080;

  /* used to create effects animation */
  --secs: 0.4s;
}
