.edh-download-status {
  display: flex;
  align-items: center;

  &--successful {
    color: #00a19c;
  }

  &--progress {
    color: #3f3c4c;
  }

  &--failed {
    color: #da1e28;
  }

  &__name {
    font-weight: bold;
    font-size: 16px;
  }
}
