@import 'assets/styles/mixin/responsive.scss';

.edh-breadcrumb {
  margin: 1.5rem 0rem 0rem;
  @include responsive-mobile {
    margin-top: 32px;
  }

  &__items {
    display: flex;
    align-items: center;
  }

  & a {
    color: #9f9eae;
    text-decoration: none;
  }

  &__item {
    display: flex;
    align-items: center;
    color: #9f9eae;
    font-size: 12px;
    font-weight: 400;

    &:hover {
      color: var(--first-color);
    }

    &--active {
      color: #3f3c4c;
      cursor: context-menu;

      &:hover {
        color: var(--title-color);
      }
    }

    & > svg {
      margin-left: 6px;
      margin-right: 6px;

      @include responsive-mobile {
        margin-left: unset;
        margin-right: unset;
      }
    }
  }

  &__title-page {
    display: flex;
    align-items: center;
    & .title-page__current-path {
      color: var(--primary-900);
      font-weight: normal;
      font-size: 32px;
      line-height: 1.25;
      margin: 6px 0px;
      word-break: break-word;

      @include responsive-mobile {
        font-size: 20px;
      }
    }
  }
}
