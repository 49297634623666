@import 'assets/styles/mixin/responsive.scss';

.am-user-review-card {
  & + & {
    margin-top: 32px;
  }
  border-radius: 5px;
  background: #e6e6e6;
  border: 1px solid #eeeeee;

  .card {
    &__container {
      border: 1px solid #eeeeee;
      border-radius: 5px;
      background: #f5f5f5;
    }
    &__header {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0 16px;
      color: var(--black-4-color);
      padding: 16px 24px;
      border-bottom: 1px solid #dcdcdc;
    }
    &__body {
      position: relative;
      padding: 16px 24px;
      border-radius: 0 0 5px 5px;
      display: flex;
      flex-direction: column;
      & #multi-clamp-button {
        padding-left: 1px;
        color: var(--first-color);
        background-color: transparent;
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;
      }
      .divider {
        width: 100%;
        margin-left: 5px;
        align-self: center;
      }

      & .edh-text-editor .quill {
        & .ql-mention-list-container {
          max-height: 220px;

          @include responsive-mobile {
            max-height: 232px;
          }
        }

        & .ql-mention-list-item {
          & .ql-mention-user {
            &__name {
              width: auto;
            }
          }
        }
      }
    }
    &__footer {
      padding: 11.5px 24px;
      display: flex;
      gap: 36px;
      align-items: center;
      &__label {
        font-style: italic;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #6d6f76;
        margin-left: 6px;
      }
      svg {
        color: #9f9eae;
      }
    }
  }
  .item {
    &__avatar {
      width: 61px;
      height: 61px;
      font-size: 26px;
      line-height: 61px;
      border-radius: 5px;
      flex-shrink: 0;
    }

    &__fullName {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 2px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__user-info {
      flex-grow: 1;
      width: 100%;
      overflow: hidden;
    }
    &__role-title {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 7px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__update-time {
      align-self: flex-start;
      flex-shrink: 0;
      justify-self: flex-end;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #b4b4b4;
    }
    &__review-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 6px;
      color: var(--black-4-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: fit-content;
      max-width: 100%;
    }
    &__review-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #828282;
      padding-left: 4px;
      white-space: pre-wrap;
      word-wrap: break-word;
      &.hide {
        display: -webkit-box;
        max-height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    &__show-more {
      position: absolute;
      right: 16px;
      bottom: 8px;
      color: var(--first-color);
      font-weight: 500;
      font-size: 12px;
      padding-left: 16px;
      cursor: pointer;
      &::after {
        content: '...';
        position: absolute;
        left: 0;
        bottom: 0;
        color: #828282;
        font-size: 14px;
      }
    }
    &__reply {
      margin-top: 24px;
      background: var(--body-color);
      border-radius: 8px;
      padding: 16px;
      &__title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--first-color);
        margin-top: 25px;
        width: fit-content;
      }
      &__avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      &__divider {
        border-top: 1px solid #efefef;
        margin: 24px 0;
        width: 100%;
      }

      &__list {
        display: flex;
        flex-direction: column;
        gap: 16px 0;
        padding: 16px 0;
        overflow-y: auto;
        &::-webkit-scrollbar-track {
          background: transparent;
        }
      }

      &__load-more {
        padding: 8px 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        > button {
          font-weight: 500;
          color: var(--first-color);
          background: none;
          border: none;
        }
      }
    }
    &__tag {
      margin-left: auto;
    }
    &__vote-icon {
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg.active {
        path {
          fill: #009088;
        }
      }
      span {
        user-select: none;
      }
    }
    &__thread {
      &__container {
        max-height: fit-content;
        @include responsive-mobile {
          margin: 7px 0;
        }
      }
      &__comment {
        display: flex;
        flex-direction: column;
        gap: 25px 0;
      }
      &__reply {
        margin-top: 8px;
      }
    }
  }
}
