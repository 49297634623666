.edh-video {
  width: 100%;
  height: 180px;

  background-color: var(--first-color);

  border-radius: 4px;
  overflow: hidden;

  &__container {
    position: relative;
    width: 100%;
    height: 148px;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &::-webkit-media-controls-fullscreen-button {
        display: none;
      }
    }
  }

  &__play-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 26px 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    );

    z-index: 1;

    h4 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;

      letter-spacing: 0.25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      color: var(--white-color);
    }

    button {
      width: fit-content;
      height: fit-content;
      padding: 0;
      border: none;
    }
  }

  &__thumbnail {
    position: absolute;
    inset: 0;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  &__title {
    padding: 5px 12px;

    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: 0.25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: var(--white-color);
  }
}
