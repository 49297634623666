@import 'assets/styles/mixin/responsive.scss';

.edh-user-guide {
  &:focus,
  .btn-custom-primary {
    box-shadow: none !important;
  }

  &__help {
    &.btn {
      @include responsive-mobile {
        padding: 0 5px;
      }
    }

    &.dark svg {
      color: #8d8d8d;
    }
  }
}
