.edh-alerts {
  position: fixed;
  top: 52px;
  left: 50%;
  transform: translateX(-50%);
  text-align: -webkit-center;
  z-index: var(--zindex-alert);

  &:has(.edh-alerts-highest) {
    z-index: 100000;
  }
}
