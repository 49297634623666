@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter/Inter-Light.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/Inter/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/Inter/Inter-SemiBold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/Inter/Inter-Bold.ttf');
}

@font-face {
  font-family: 'MuseoSans100';
  src: url('assets/fonts/MuseoSans/MuseoSans_100.otf');
}

@font-face {
  font-family: 'MuseoSans300';
  src: url('assets/fonts/MuseoSans/MuseoSans_300.otf');
}

@font-face {
  font-family: 'MuseoSans500';
  src: url('assets/fonts/MuseoSans/MuseoSans_500.otf');
}

@font-face {
  font-family: 'MuseoSans700';
  src: url('assets/fonts/MuseoSans/MuseoSans_700.otf');
}

@font-face {
  font-family: 'MuseoSans900';
  src: url('assets/fonts/MuseoSans/MuseoSans_900.otf');
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

//
@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-Light.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-LightItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-LightItalic.ttf') format('truetype');
  font-weight: italic;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish/Mulish-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
