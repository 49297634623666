@import 'assets/styles/mixin/responsive.scss';

.edh-logged-user {
  position: relative;

  &__btn.btn {
    @include responsive-mobile {
      padding: 0 5px;
    }
  }

  &__popover {
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0px;
    width: 20.375rem;
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 0.25rem;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    z-index: var(--zindex-popover);

    @include responsive-mobile {
      top: calc(100% + 0.8rem);
    }

    &::after {
      content: ' ';
      position: absolute;
      bottom: 100%;
      right: 1.25rem;
      width: 1.25rem;
      border-bottom: 0.625rem solid #ffffff;
      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;

      @include responsive-mobile {
        right: 0.85rem;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
  }

  &__info-profile {
    padding-left: 0.5rem;

    &-name {
      margin-bottom: 0;
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--text-color);
    }

    &-email {
      font-size: 0.625rem;
      line-height: 1.4rem;
      color: #bdbdbd;
    }
    &-display-none-email {
      display: none;
    }
  }

  &__btn-group {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    column-gap: 8px;

    button {
      font-size: 12px;
      font-weight: 500;
      height: 32px;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: -12px;

    .btn {
      font-size: 12px;
      height: 32px;
      color: var(--first-color);
    }
  }

  &__view-btn.btn {
    color: var(--first-color);

    font-size: 12px;
    font-weight: 500;

    border: 1px solid var(--first-color-light);
    border-radius: 4px;

    transition: all linear 0.25s;

    &:hover {
      cursor: not-allowed;
    }
  }

  &__avatar {
    background: var(--white-color);
    color: var(--first-color);
  }
}
