@import 'assets/styles/mixin/responsive.scss';

.edh-pagination {
  $this: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  &__left {
    display: flex;
    align-items: center;
    flex: 1;

    .form-select:disabled {
      cursor: not-allowed;
      background-color: var(--container-color);
    }
  }
  &__left .edh-dropdown-button {
    height: 2.5rem;
    max-width: 9.5rem;
    background: var(--container-color);
    border: 1px solid #dcdce6;
    box-sizing: border-box;
    border-radius: 4px;

    &:hover {
      box-shadow: var(--box-shadow);
    }

    &__title-group {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__icon {
      margin-right: 5px;
      color: #c4c3d1;
      height: var(--h3-font-size);
      width: var(--h3-font-size);
    }

    .dropdown-toggle {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 10px 0 3px;
      height: 2.5rem;
      max-width: 9.5rem;
      white-space: normal;
      line-height: 1.25;
      color: var(--first-color);
      border: none;
      font-weight: 500;
      font-size: 12px;

      &::after {
        color: var(--first-color);
        font-size: 14px;
        margin-left: 7px;
      }
    }

    .dropdown-menu {
      min-width: 200px;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      border: none;
      padding: 24px 24px 20px 24px;
      z-index: var(--zindex-popover);
    }

    .dropdown-item {
      padding: 0 0 16px 0;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: #3f3c4c;

      &:hover {
        background-color: unset;
      }

      &:first-child {
        font-weight: bold;
        font-size: 11px;
        line-height: 120%;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #00a19c;
        pointer-events: none;
      }

      &:last-child {
        padding: 0;
      }
    }
  }
  .edh-dropdown-button.dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .dropdown-menu {
      width: 200px;
      position: absolute;
      left: 0 !important;
    }
    & .dropdown-toggle {
      & .edh-dropdown-button__title-group {
        font-size: 14px;
        width: 85px;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__page-items {
    background: var(--container-color);
    border: 1px solid #dcdce6;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    padding: 8px 0px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: var(--container-color);
      padding: 0 0.625rem;

      &:first-child {
        border-right: 1px solid #e7e7f0;
      }

      &:last-child {
        border-left: 1px solid #e7e7f0;
      }

      &:disabled {
        color: #c4c3d1;
        background: none;
        cursor: not-allowed;
      }
    }
  }

  &__go-page {
    display: flex;
    align-items: center;
    white-space: nowrap;

    p {
      margin: 0px 8px;

      &:last-child {
        margin-right: 0px;
      }
    }

    p,
    input {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #787587;
    }

    input {
      background: var(--container-color);
      border: 1px solid #c4c3d1;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
      border-radius: 4px;
      width: 46px;
      height: 36px;
      padding: 4px 14px;
    }
  }

  &__go-page .error {
    border: 1px solid #e82121;
  }

  .pagination {
    .page-item {
      width: 36px;
      height: 36px;
      padding: 4px;
      border-top: 1px solid #dcdce6;
      border-bottom: 1px solid #dcdce6;

      .page-link {
        height: 100%;
        border: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #3f3c4c;
        padding: 8px 0;
        text-align: center;
      }

      &.active {
        .page-link {
          background: #00a19c;
          border-radius: 4px;
          font-weight: 600;
          color: #ffffff;
        }
      }

      &.disabled {
        .page-link {
          color: #c4c3d1;
        }
      }
    }

    .page-item:first-child {
      border-left: 1px solid #dcdce6;
      .page-link {
        border-right: 1px solid #e7e7f0;

        span:first-child {
          visibility: hidden;
        }

        span:first-child::before {
          visibility: visible;
          content: '\25C0';
          font-size: 8px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      border-radius: 4px 0 0 4px;
    }

    .page-item:last-child {
      border-right: 1px solid #dcdce6;
      .page-link {
        border-left: 1px solid #e7e7f0;

        span:first-child {
          visibility: hidden;
        }

        span:first-child::before {
          visibility: visible;
          content: '\25B6';
          font-size: 8px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      border-radius: 0 4px 4px 0;
    }
  }

  @include responsive-mobile {
    flex-direction: column;

    &__left,
    &__go-page {
      display: none;
    }

    &__right {
      margin-top: 0.5rem;
    }
  }
}
