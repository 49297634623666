.rating-field {
  display: flex;
  flex-direction: column;
  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--neutral-n700);
  }
  .edh-ratingstar {
    margin: 0;
  }
  .edh-field__field {
    line-height: 0;
    margin: 2px 0;
  }
}
