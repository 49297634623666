@import 'assets/styles/mixin/responsive.scss';

.am-nav-tabs {
  &__item {
    display: flex;
    gap: 40px;
    padding: 0 28px;
    border-bottom: 1px solid var(--neutral-n200);
    @include responsive-mobile {
      overflow-x: auto;
      padding: 0;
      gap: 20px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &__name {
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    color: var(--placeholder-color);
    padding: 24px 0;
    position: relative;
    transition: all 0.2s ease-in-out;

    @include responsive-mobile {
      padding: 0 0 24px 0;
      white-space: nowrap;
    }

    @include responsive-13inch {
      font-size: 14px;
      line-height: 22px;
    }

    &.active {
      font-weight: 600;
      color: var(--black-3-color);
      position: relative;
      &::after {
        width: 100%;
      }
    }
    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 2px;
      width: 0;
      background-color: var(--first-color);
      transition: all 0.2s ease-in-out;
    }
    &::before {
      display: block;
      content: attr(title);
      font-weight: 600;
      height: 0px;
      color: transparent;
      overflow: hidden;
      visibility: hidden;
      transition: width 0.2s ease-in-out;
    }
  }
}
