@import 'assets/styles/mixin/responsive.scss';

.edh-alert {
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 90vw;
  width: max-content;
  padding: 10px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  border-radius: 4px;

  font-size: 14px;
  line-height: 20px;

  @include responsive-mobile {
    .edh-export-data-modal__alert-content {
      svg:first-child {
        width: 25px;
        height: 25px;
      }
    }
  }

  &.danger {
    color: #a91a1f;
    background-color: #fbe2e2;

    border-color: #da2228;
    border-width: 1px 1px 1px 4px;
  }

  &.warning {
    background: #fff7e5;
    color: #2d2b39;
  }

  &.success {
    color: #00580f;
    background-color: #e5f4e7;

    border-color: #007724;
    border-width: 1px 1px 1px 4px;
  }

  &.success-plus {
    color: #000000;
    background-color: #ffffff;

    border-color: #007724;
    border-width: 1px 1px 1px 4px;
  }

  &.warning-plus {
    background-color: var(--yellow-4-color);
    color: #65490e;
    border-color: #e29e20;
    border-width: 1px 1px 1px 4px;
    border-radius: 0;
    align-items: flex-start;
    .btn {
      color: #65490e;
    }
  }
  &:not(:last-child) {
    margin-bottom: 2px;
  }
}
