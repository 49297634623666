@import 'assets/styles/mixin/responsive.scss';

.report-content {
  .am-user-review-card {
    background-color: var(--container-color);
    border: none;

    .card__container {
      background-color: var(--container-color) !important;
      border-radius: 8px 8px 0 0;
      border: none;
    }
    .card__footer {
      border-radius: 8px;
      background-color: var(--neutral-n100);
      border: none;
    }
  }

  &.hide &__header {
    &-icon {
      transform: none;
    }
    margin-bottom: 8px;
  }
  &__header {
    display: flex;
    gap: 20px 0;
    flex-direction: row;
    margin-bottom: 24px;
    position: relative;
    align-items: center;
    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: var(--black-3-color);
    }
    .edh-am-dropdown-button {
      height: 47px;
      width: 156px;
      max-width: none;
      min-width: 80px;
      margin-left: auto;
      .dropdown-toggle {
        width: 100%;
        height: 100%;
        padding: 0 14px 0 12px;
        align-items: center;
        justify-content: space-between;
        color: var(--black-3-color);
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-transform: uppercase;
      }
      .dropdown-toggle::after {
        color: rgba(0, 0, 0, 0.3);
      }
    }
  }
  &__body {
    overflow-y: scroll;
    margin-right: -18px;
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &__no-data {
      line-height: 20px;
      font-weight: 500;
      margin: 112px 0;
      color: var(--black-6-color);
      text-align: center;
      letter-spacing: 0.25px;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: -3.5px;
  }
  & .edh-select__menu {
    padding: 0;
  }

  &__details-note {
    font-style: italic;
    font-size: 10px;
    line-height: 12px;
    color: var(--neutral-n700);
  }
  .pagination {
    background-color: var(--white-color);
  }
  @include responsive-mobile {
    .am-user-review-card {
      .card {
        &__container {
          background: var(--container-color);
        }
        &__footer {
          padding: 11px 8px;
          border-radius: 8px;
          gap: 18px;
          background-color: var(--neutral-n100);
          .edh-status--tag {
            background: var(--emphasis-high-n900) !important;
          }
        }
        &__header {
          width: unset;
          padding: 16px 0 8px;
          margin: 0 16px;
          grid-template-columns: 50px 1fr;
          grid-template-areas:
            'avatar fullName fullName'
            'avatar roleTitle roleTitle'
            'avatar star star';
        }
        &__body {
          padding: 8px 16px 24px;
        }
      }
      .item {
        &__avatar {
          width: 50px;
          height: 50px;
          line-height: 50px;
        }
        &__reply {
          padding: 12px 8px;
          margin-top: 16px;
          &__title {
            margin-top: 16px;
            font-size: 14px;
          }
          &__divider {
            margin: 14px 0;
          }
        }
        &__fullName {
          font-size: 14px;
          line-height: 17px;
        }
        &__role-title {
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 4px;
        }
        &__update-time {
          display: none;
        }
        &__review-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 8px;
        }
        &__review-description {
          padding-left: 0;
        }
      }
    }
    &__header {
      margin-bottom: 17px;
      h3 {
        margin-right: 6px;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }
      .edh-am-dropdown-button {
        height: 40px;
        width: unset;
        min-width: 115px;
        border: 1px solid var(--gray-color3);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
        border-radius: 4px;
        .dropdown-toggle {
          text-transform: none;
          &::after {
            border-top: 0.4em solid;
            border-right: 0.4em solid transparent;
            border-bottom: 0;
            border-left: 0.4em solid transparent;
          }
        }
      }
    }
  }
}
