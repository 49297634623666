.edh-toast {
  padding: 20px 24px;
  width: 424px;
  background: var(--container-color);
  color: var(--text-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

  .btn {
    padding: 4px 0px;
    font-weight: bold;
    font-size: 12px;
  }

  &--success,
  &--danger,
  &--warning {
    color: #ffffff;

    .btn {
      color: #ffffff;
    }
  }

  &--success {
    background: #00a19c;
    border-color: #00a19c;
  }

  &--danger {
    background: #e82121;
    border-color: #e82121;
  }

  &--warning {
    background: #fff7e5;
    border-color: #ffc107;
    color: #2d2b39;

    .btn {
      color: #2d2b39;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;

    span {
      width: 90%;
      overflow-wrap: break-word;
    }

    button {
      display: flex;
      align-items: flex-start;
    }
  }

  &__body {
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 0.875rem;
  }
}
