.edh-loading-balls {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--zindex-fixed);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--container-color);
  cursor: wait;

  &--backdrop {
    background-color: #aaaaaa66;
  }

  &__balls-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #424242;
    margin-right: 0.75rem;
  }

  &__ball {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #7365b1;
    margin-right: 10px;
    animation: ball 0.5s ease infinite alternate;

    &--style-two {
      animation-delay: 0.1s;
    }

    &--style-three {
      animation-delay: 0.2s;
    }
  }

  @keyframes ball {
    to {
      transform: translate(-10px);
    }
  }
}
