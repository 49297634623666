@import 'assets/styles/mixin/responsive.scss';

.edh-comment-input {
  display: flex;

  &__body {
    position: relative;
    width: 100%;
    margin-left: 16px;
    height: fit-content;
    background-color: #ffffff;
    border-radius: 4px;
    &.focus {
      border: 1px solid #c4c3d1;
      border-radius: 4px;
    }
    @include responsive-mobile {
      margin-left: 8px;
    }
  }
  &__emoji {
    position: absolute;
    width: fit-content;
    bottom: 30px;
    right: 0;
    transform: translateX(-25%);
    z-index: 1;
  }
  &__wrap {
    cursor: default;
    position: relative;
  }
  &__textarea {
    border: none !important;
    overflow-y: hidden;
    .quill {
      resize: none;
      width: 100%;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: none;
      font-style: normal;
      font-size: 12px;
      line-height: 15px;
      .ql-container {
        position: relative;
      }
      .ql-editor {
        font-size: 14px;
        padding: 10px 8px;
        overflow: hidden;
      }
      > .ql-container > .ql-editor.ql-blank::before {
        top: 8px;
        left: 8px;
      }
      .mention {
        color: var(--blue-color-2);
      }
    }

    .edh-text-editor__actions {
      display: none !important;
    }

    &.focus {
      overflow-y: unset;
      .quill {
        overflow: auto;
        height: 71px;
        width: 100%;
        word-break: break-word;
        .ql-container {
          border: none;
          height: 100%;
          position: unset;
        }
        .ql-editor {
          overflow-y: scroll;
        }
      }
      .edh-text-editor {
        &__actions {
          display: flex !important;
          margin-top: 12px;
          align-items: center;
          justify-content: flex-end;
          column-gap: 12px;
          padding: 4px 8px;
          background: var(--body-color);
          box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.07);
          border-radius: 0px 0px 4px 4px;
          border: none;
          @include responsive-mobile {
            padding: 4px 0;
            column-gap: 4px;
          }
        }
        &__action {
          display: flex;
          background-color: transparent;
          border: none;
          width: unset;
          height: unset;

          svg {
            width: 16px;
            height: 16px;
            vertical-align: unset;
          }

          &.primary {
            padding: 8px 12px;
            background-color: var(--first-color);
            border-radius: 4px;
          }

          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
  &__footer {
    height: 39px;
    width: 100%;
    background: var(--body-color);
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.07);
    border-radius: 0px 0px 4px 4px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0 12px;
    padding-right: 24px;
  }

  .hidden {
    display: none;
  }

  .edh-avatar {
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
  }
  .edh-user-info-avatar__info {
    display: none;
  }

  & .edh-text-editor {
    & .quill {
      & .ql-mention-list-container {
        bottom: 100% !important;
        top: unset !important;
        max-height: calc(58px * 3);
        .ql-mention-list {
          padding: 0;
          &::before {
            display: block;
          }
        }
      }

      & .ql-mention-list-item {
        min-height: 58px;
      }
    }
  }
}

.edh-text-editor__emoji {
  @include responsive-mobile {
    transform: translateX(0);
    bottom: unset;
    text-align: right;
    .emoji-mart {
      text-align: left;
      max-width: 85% !important;
    }
  }
}
