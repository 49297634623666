.edh-feedback-form-modal {
  .modal {
    &-header,
    &-footer {
      padding: 1.5rem;
    }
    &-header {
      align-items: flex-start;
      border-bottom: none;
      padding-bottom: 0;

      &__page {
        color: var(--first-color);
        font-size: 11px;
        font-weight: bold;
        line-height: 1.3;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      &__title {
        color: var(--black-color);
        font-size: 24px;
        font-weight: 500;
        line-height: 1.3;
        margin-top: 6px;
      }

      .btn-close {
        background-size: 14px;
      }
    }

    &-body {
      padding: 1rem 1.5rem 22px 1.5rem;
      & .edh-input--textarea {
        height: 11.3125rem;
      }
    }

    &-footer {
      padding-top: 0;
      border-top: none;
    }
  }
  &__succeed {
    text-align: center;

    font-style: normal;

    & h3 {
      margin: 34px 0 1rem 0;
      font-family: 'MuseoSans300';
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2;
      letter-spacing: -0.025em;
      color: var(--green-tertiary);
    }

    & p {
      font-family: 'MuseoSans300';
      font-weight: 400;
      font-size: 18px;
      line-height: 1.6;
      color: var(--black-color);
    }

    &__done {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1.5rem 0 0 0;

      & button {
        font-weight: 500;
        font-size: 12px;
        line-height: 1.3;
        padding: 8px 12px;
      }
    }
  }
}
