@import 'assets/styles/mixin/responsive.scss';

.am-tab-history {
  min-height: 660px;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--body-color);
  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--black-3-color);
    margin-bottom: 24px;
  }
  .edh-message-no-data {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
  @include responsive-mobile {
    padding: 0;
    &__title {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 16px;
    }
  }
}
.edh-loading-balls__custom {
  margin-left: 18px;
}
