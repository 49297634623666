@import 'assets/styles/mixin/responsive.scss';

.am-tab-report {
  min-height: 500px;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--body-color);
  &__divider {
    border-top: 1px solid var(--border-color);
    margin: 24px 0;
  }
  @include responsive-mobile {
    padding: 0;
    &__divider {
      width: 100% !important;
      visibility: hidden;
      margin: 8px 0 !important;
    }
  }
}
