@import 'assets/styles/mixin/responsive.scss';

.edh-sidebar-overlay {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.6);
  z-index: 9998;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.edh-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--with-sidebar);
  height: 100%;
  background: var(--first-color);
  transition: all 0.5s ease;
  overflow-y: overlay;

  @include responsive-mobile {
    width: 100%;
    max-width: 320px;

    z-index: 9999;
    box-shadow: 4px 3px 0 0 rgba($color: #000000, $alpha: 0.03);
    transform: translateX(-110%);

    &.show {
      transform: translateX(0);
    }
  }

  &-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 60px;
      background-color: #2baaa7;

      @include responsive-mobile {
        width: fit-content;
        padding-left: 25px;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: transparent;
      }
    }

    &__close-btn {
      margin-right: 20px;
    }
  }

  &-nav {
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 14px 4px;
      transition: all 0.4s ease;

      @include responsive-mobile {
        flex-direction: row;
        padding: 14px 25px;
        column-gap: 20px;

        &:not(&:last-child) {
          border-bottom: 1px solid var(--gray-color);
        }
      }

      &.active {
        cursor: default;
      }

      &.active,
      &:hover {
        text-decoration: none;
        background-color: var(--first-color-light);
      }
    }

    &__icon {
      position: relative;

      &.jai {
        font-family: 'MuseoSans700';
        font-weight: 600;
      }

      @include responsive-mobile {
        width: 38px;
        min-width: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 100%;
      }
    }

    &__label {
      width: 100%;
      margin-top: 5px;
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: var(--white-color);

      &.hiding {
        overflow: hidden;
        white-space: nowrap;
      }

      @include responsive-mobile {
        margin-top: 0;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .flicker-text {
    animation: animate-flicker-text linear 0.75s;
    animation-iteration-count: infinite;
  }
}

@keyframes animate-flicker {
  from {
    background-color: var(--white-color);
  }
  to {
    background-color: var(--yellow-2-color);
  }
}

@keyframes animate-flicker-text {
  from {
    color: var(--white-color);
  }
  to {
    color: var(--yellow-2-color);
  }
}
