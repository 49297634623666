@import 'assets/styles/mixin/responsive.scss';

.edh-modal {
  @include responsive-mobile {
    overflow-y: overlay;
    padding: 0 !important;
  }

  &::-webkit-scrollbar-button {
    @include responsive-mobile {
      display: block;
      height: 2px;
    }
  }

  &__dialog.modal-dialog {
    max-width: 100vw;
    width: fit-content;

    @include responsive-mobile {
      width: unset;
    }
  }
}
