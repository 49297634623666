@import 'assets/styles/mixin/responsive.scss';

.am-tab-overview {
  &__model {
    border-radius: 8px;
    padding: 24px 24px 94px;
    background-color: var(--body-color);

    &__row {
      margin-bottom: 24px;

      p {
        line-height: 22px;
        color: var(--black-3-color);
      }
    }

    &__title {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 18px;
    }

    &__desc {
      font-weight: 400;
      font-size: 14px;

      &--child {
        margin-left: 30px;
        font-size: 14px;
        text-indent: -10px;
      }
    }
  }
  &__user-review {
    margin-top: 40px;
    display: grid;
    grid-template-areas:
      'card form '
      'pagination pagination';
    grid-template-columns: 52%;
    gap: 8px 32px;

    @include responsive-13inch {
      display: flex;
      flex-direction: column;
    }

    .user-reviews {
      grid-area: card;
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 24px;
        h3 {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: var(--black-color);
        }
        .edh-am-dropdown-button {
          height: 47px;
          width: 156px;
          max-width: none;
          min-width: 80px;
          .dropdown-toggle {
            width: 100%;
            height: 100%;
            padding: 0 14px 0 12px;
            align-items: center;
            justify-content: space-between;
            color: var(--black-3-color);
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-transform: uppercase;
          }
          .dropdown-toggle::after {
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
      &__content {
        position: relative;
        overflow-y: scroll;
        height: auto;
        margin-right: -18px;
        max-height: 540px;
      }
    }
    .review-form {
      grid-area: form;
      @include responsive-13inch {
        margin-top: 40px;
      }
    }
    .edh-pagination {
      grid-area: pagination;
      .pagination {
        background-color: white;
      }
    }
  }
  @include responsive-mobile {
    &__model {
      background-color: transparent;
      padding: 16px;
      &__title {
        font-size: 16px;
      }
    }
    &__user-review {
      grid-template-areas: 'card' 'pagination' 'form';
      grid-template-columns: 100%;
      margin-top: 0;
      flex-direction: column;
      .user-reviews {
        &__header {
          align-items: center;
          margin-bottom: 16px;
        }
        &__content {
          &::-webkit-scrollbar-track {
            background: transparent;
          }
        }
      }
      .user-review-card .card__header {
        grid-template-areas:
          'avatar fullName fullName'
          'avatar roleTitle roleTitle'
          'avatar star star';
      }

      .user-reviews__container {
        width: 100%;
        margin: 18px 0 0;
      }
      .review-form {
        width: 100%;
        margin: 18px 0 0;
        &__header {
          gap: 4px 0;
          margin-bottom: 16px;
        }
        &__body {
          padding: 16px 16px 0;
          background-color: white;
          border-radius: 8px;
        }
        &__footer {
          margin-top: 16px;
          button {
            flex: 1;
          }
        }
      }
    }
  }
}
