@import 'assets/styles/mixin/responsive.scss';

.edh-check {
  $this: &;

  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__label {
    font-size: 15px;
    color: var(--text-color);
    margin-left: 0.5rem;
    word-break: break-word;

    p {
      max-width: 110px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include responsive-13inch {
        max-width: 80px;
      }
    }
  }

  &__input {
    width: 1.25rem;
    height: 1.25rem;
    min-width: 1.25rem;
    box-sizing: border-box;
    border: 2px solid #dcdce6;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--secs);
    background-color: var(--white-color);
  }

  &--is-reverse {
    flex-flow: row-reverse;

    #{$this}__label {
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }

  &--sm {
    #{$this}__input {
      width: 1rem;
      height: 1rem;
      min-width: 1rem;
    }
  }

  &--md {
    #{$this}__input {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &--lg {
    #{$this}__input {
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  &--checkbox {
    #{$this}__input {
      border-radius: 4px;
    }

    #{$this}__input--is-checked {
      background-color: #00a19c;
      border: 2px solid #0d7e7a;
    }
  }

  &--radio {
    #{$this}__input {
      border-radius: 50%;
    }
  }

  &--switch {
    .edh-check__input {
      background-color: var(--blue-color-3);
    }

    .edh-check__input--is-checked {
      background-color: var(--first-color);
    }

    #{$this}__input {
      width: 36px;
      border: none;
      background-color: #00a19c;
      border-radius: 10px;
      position: relative;

      svg {
        position: absolute;
        transform: translateX(0);
        left: 0%;
        transition: all var(--secs);
      }
    }

    #{$this}__input--is-checked {
      svg {
        left: 100%;
        transform: translateX(-100%);
      }
    }
  }

  &--disabled {
    cursor: not-allowed;

    #{$this}__input {
      background-color: #e7e7f0;

      svg {
        color: #e7e7f0;
      }
    }
  }

  &--disabled .edh-check__input--is-checked {
    border-color: #e7e7f0;

    svg {
      color: #ffffff;
    }
  }
}

.edh-checked--disabled:has(.edh-check__input) {
  .edh-check__input {
    background-color: #e7e7f0;

    svg {
      color: #e7e7f0;
    }
  }
}

.edh-checked--disabled:has(.edh-check__input) {
  .edh-check__input.none-tick {
    svg {
      path {
        color: transparent;
      }
    }
  }
}

.edh-check--disabled.edh-check--is-checked:has(.edh-check__input.edh-check__input--is-checked) {
  .edh-check__input {
    background-color: #e7e7f0;
    border: none;
    border-radius: 5px;

    svg {
      color: var(--blue-color-3);
    }
  }
}

.edh-check.edh-checked--disabled.edh-check--disabled.edh-check--is-reverse.edh-check--checkbox.edh-check--md:has(.edh-check__input) {
  .edh-check__input {
    background-color: transparent;

    svg {
      color: transparent;
    }
  }
}
