@keyframes animate-translate-left-to-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes animate-translate-top-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes animate-opacity-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate {
  animation: ease-in-out;

  &[animate-type='translate-left-to-right'] {
    animation-name: animate-translate-left-to-right;
  }

  &[animate-type='translate-top-down'] {
    animation-name: animate-translate-top-down;
  }

  &[animate-type='opacity-show'] {
    animation-name: animate-opacity-show;
  }

  &[animate-duration='1s'] {
    animation-duration: 1s;
  }

  &[animate-duration='0.25'] {
    animation-duration: 0.25s;
  }

  &[animate-duration='0.5'] {
    animation-duration: 0.5s;
  }
}
