.edh-popup {
  padding: 24px 0;

  &__header {
    padding-bottom: 16px;
  }

  &__category {
    font-weight: 700;
    font-size: 11px;
    line-height: 120%;

    letter-spacing: 1px;
    text-transform: uppercase;

    color: var(--first-color);
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;

    color: var(--emphasis-high-n900);
  }

  &__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 3px;

    background-color: transparent;
    color: var(--gray-color);

    border: none;
    transition: color ease-in-out 0.25s;

    &:hover {
      color: var(--gray-color4);
    }

    svg {
      color: currentColor;
    }
  }

  &__btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 8px;

    &--full {
      width: 100%;
    }

    &.footer {
      align-items: stretch;
    }
  }
}

.edh-popup-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;

  padding: 0 24px;
}
