@import 'assets/styles/mixin/responsive.scss';

.edh-field {
  position: relative;

  &__label,
  &__label-required:after,
  &__remarks,
  &__message {
    font-size: 13px;
    color: #d50000;
  }

  &__label {
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #625e70;
    margin-bottom: 0.5rem;
  }

  &__label-required:after {
    content: ' *';
  }

  &__message {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    height: 1rem;
  }

  &__field {
    position: relative;

    &--disabled {
      cursor: not-allowed;
    }

    .edh-select {
      .edh-select__control {
        cursor: pointer;
      }
      .edh-select__control--is-disabled {
        background-color: var(--body-color);

        .edh-select__value-container.edh-select__value-container--has-value {
          .edh-select__single-value.edh-select__single-value--is-disabled {
            color: var(--gray-color3);
          }
        }
      }
    }

    .edh-search-bar-with-suggestion {
      box-shadow: none;
      &__search {
        height: 41px;

        .edh-search-input {
          padding: 2px 8px;
          height: inherit;
          width: 100%;

          @include responsive-13inch {
            width: auto;

            input {
              width: 95%;
            }
          }
        }
      }

      &__dropdown {
        max-height: 300px;

        &--menu-list {
          max-height: 250px;
        }
      }
    }
  }
}

.edh-field.edh-dataset-name-field {
  .edh-field__field {
    .edh-search-bar-with-suggestion {
      &__dropdown {
        max-height: 250px;

        &--menu-list {
          max-height: 200px;
        }
      }
    }
  }
}
