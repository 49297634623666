@import 'assets/styles/mixin/responsive.scss';

.edh-message-no-data {
  padding: 0.5rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__title {
    color: #000000;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    word-break: break-word;
  }

  &__description {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #a8a8a8;
    margin: 0.5rem 0rem;
    text-align: center;
    word-break: break-word;
  }

  &.isLoading {
    * {
      display: none;
    }
  }
  @include responsive-mobile {
    &__title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
