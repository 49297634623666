@import 'assets/styles/mixin/responsive.scss';

.model-content {
  &__text-to-image {
    display: grid;
    width: 100%;
    gap: 16px 24px;
    grid-template-areas: 'prompt image' 'prompt-button image-button';
    grid-template-columns: 13fr 14fr;
    .sample-data__action-button {
      padding: 7px 12px;
      align-self: flex-end;
      display: flex;
      margin-left: auto;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      gap: 4px;
      color: var(--gray-color);
      cursor: not-allowed;
      svg {
        width: 13px;
      }
      &:not(:disabled) {
        cursor: pointer;
        background: var(--first-color);
        color: var(--white-color);
        svg {
          path {
            fill: var(--white-color);
          }
        }
      }
      &.download-button {
        grid-area: image-button;
      }
      &.run-button {
        grid-area: prompt-button;
        align-self: flex-start;
      }
    }
    .sample-data {
      &__image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column;
        padding: 24px;
        border-radius: 8px;
        background: rgba(238, 238, 244, 0.7);
        border: 1px solid var(--gray-color3);
        width: 100%;
        min-height: 274px;
        .edh-loading-balls {
          border-radius: 8px;
          background: rgba(238, 238, 244, 0.7);
        }

        .edh-watermark-image {
          &__no-data {
            text-align: center;
          }

          &__image-nodata {
            display: block;
            margin: 0 auto;
          }
        }
      }
      &__image {
        width: 100%;
        object-fit: contain;
        border-radius: 8px;
      }
      @include responsive-mobile {
        &__image-container {
          display: block;
        }
      }
    }

    .sample-data {
      &__form-container {
        grid-area: prompt;
        padding: 24px;
        border-radius: 8px;
        background: rgba(238, 238, 244, 0.7);
        border: 1px solid var(--gray-color3);
      }
      &__label {
        color: var(--black-4-color);
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 8px;
      }
      &__text {
        resize: none;
        width: 100%;
        background: var(--white-color);
        border: 8px solid transparent;
        border-right: none;
        outline: 1px solid var(--gray-color3);
        border-radius: 4px;
        font-size: 14px;
        line-height: 140%;
        color: var(--black-3-color);
        box-sizing: border-box;
        overflow-y: scroll;
        margin-bottom: 16px;
        span {
          white-space: pre-line;
          font-weight: 400;
          color: var(--neutral-n900, #2d2b39);
          font-size: 14px;
          line-height: 140%;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          border: 2px solid transparent;
        }
      }
      &__error-message {
        color: var(--R50, #e82121);
        font-size: 14px;
        transform: translateY(-8px);
      }
      &__model-description {
        width: 100%;
      }
    }
  }

  @include responsive-13inch {
    &__text-to-image {
      grid-template-areas: 'prompt' 'prompt-button' 'image' 'image-button';
      grid-template-columns: 1fr;
    }
  }

  @include responsive-mobile {
    &__text-to-image {
      grid-template-areas: 'prompt' 'prompt-button' 'image' 'image-button';
      grid-template-columns: 1fr;
    }
  }
}
