@import 'assets/styles/mixin/responsive.scss';

.edh-notification-item {
  padding: 16px 28px 16px 36px;
  display: flex;
  position: relative;
  justify-content: space-between;

  &.unread {
    background: #f5f5f5;
  }

  &__left {
    display: flex;
    width: 100%;
    cursor: pointer;
  }

  &__dot {
    background: #9b151d;
    border-radius: 32px;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 24px;
    left: 16px;
  }

  &__content {
    width: 313px;
    margin-left: 8px;
  }

  &__title {
    font-size: 10px;
    line-height: 14px;
    color: #6f6f6f;
  }

  &__action {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    &--content {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #212121;
      margin-top: 6px;
    }
  }

  &__time {
    font-size: 10px;
    line-height: 14px;
    color: #a8a8a8;
    margin-top: 6px;
  }
}
