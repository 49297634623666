@import 'assets/styles/mixin/responsive.scss';

.edh-am-keyword-skillset-template-modal {
  padding: 0 !important;

  .modal-dialog {
    max-width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: center;

    .modal-content {
      width: unset;
      max-width: 100%;
    }

    .modal-header {
      padding: 1.5rem 1.5rem 0;
      margin-bottom: 1rem;
      border-bottom: none;

      .btn-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0.5rem;
        margin: 0;
      }
    }

    .modal-body {
      margin: 1rem 1.5rem;
      padding: 0;

      .template-modal__table-zoom {
        pointer-events: none;
        margin-top: 1.5rem;
        display: block;
        .edh-react-table__table {
          height: 100%;
        }
        .table-body {
          .tr {
            height: unset;
            min-height: 57px;
          }
        }
        .edh-table-call-text-editor__text {
          overflow-x: initial;
          text-overflow: initial;
          -webkit-line-clamp: unset;
          -webkit-box-orient: vertical;
        }
      }
      .template-modal__zoom-out {
        position: absolute;
        left: 24px;
        bottom: 16px;
        border: none;
        border-radius: 32px;
        padding-bottom: 2px;
        width: 32px;
        height: 32px;
        background-color: var(--first-color);
      }
      .edh-am-animated-container {
        padding-bottom: 50px;
        overflow: hidden;
      }
    }

    .modal-footer {
      padding-top: 0;
      border-top: none;

      &__submit-button {
        position: relative;

        .submit-button-tooltip {
          opacity: 1;
        }
      }
      .edh-btn.outlined:has(.introduction-popup__container) {
        background-color: var(--white-color, #fff);
        color: var(--first-color, #00a19c);
        border-color: var(--first-color, #00a19c);
      }
    }
  }
  &:has(.introduction-popup__container) {
    .edh-react-table__table {
      overflow: hidden;
    }
  }
  @include responsive-mobile {
    .requirement-note {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #da2228;
      padding: 0 24px;
      margin-bottom: 12px;
    }
    .modal-dialog {
      .modal-body {
        .template-modal__zoom-out {
          left: 4px;
          bottom: 20px;
        }
      }
    }

    .modal-footer {
      display: flex;
      flex-direction: column;
      padding: 12px 20px;
      > * {
        width: 100%;
      }
      .edh-btn {
        order: 2;
        width: 100%;
      }
    }
  }
}
