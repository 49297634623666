@import 'assets/styles/mixin/responsive.scss';

.edh-table-view-column {
  position: relative;

  .btn:focus {
    box-shadow: none;
  }

  .btn {
    @include responsive-mobile {
      padding: 0 2px;
    }
  }

  &__menus {
    z-index: var(--zindex-popover);
    max-height: 357px;
    max-width: 300px;
    border: none;

    .popover-arrow {
      display: none;
    }

    @include responsive-mobile {
      width: 100%;
      max-width: 248px;
    }

    @include responsive-mobile {
      width: 100%;
      max-width: 248px;
    }

    .card {
      border: unset;
      background: var(--container-color);
      box-shadow: var(--box-shadow);
      border-radius: var(--border-radius);
    }

    .card-header {
      font-weight: bold;
      font-size: 11px;
      line-height: 120%;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #00a19c;
      padding: 24px 24px 16px 24px;
      background-color: unset;
      border: unset;
    }

    .card-body {
      padding: 0 24px;
      max-height: 270px;
      overflow: hidden overlay;
      border: unset;
    }

    .card-footer {
      border: unset;
      padding: 16px 24px 24px 24px;
      background-color: unset;
      display: flex;
    }
  }

  &__list {
    width: 100%;
  }

  &__item {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 16px;
    align-items: center;

    svg {
      color: #e7e7f0;
    }
  }

  &__item:last-child {
    padding-bottom: unset;
  }

  &__input_checkbox {
    .edh-check {
      &__label {
        display: block;
        flex: 1;
        margin-left: 7.75px;
        font-size: 15px;
        line-height: 24px;
        color: #3f3c4c;
      }
    }
  }

  &__item-disable {
    svg {
      color: #fafafa;
      cursor: not-allowed;
    }
    .edh-check__label {
      color: #c4c3d1;
    }

    & .edh-check__input {
      border: 2px solid #dcdce6;
    }

    & .edh-check__input svg {
      color: #ffffff !important;
    }
  }

  &__footer-reset-btn {
    color: var(--first-color);
    font-weight: 500;
    padding: 0;
    margin-left: 11.5px;
  }
}
