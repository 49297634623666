@import 'assets/styles/mixin/responsive.scss';

.am-preview-subtab {
  padding: 30px 24px 24px;

  &__model-section {
    position: relative;
    margin-bottom: 17px;
    padding-top: 23px;
  }

  &__model-header {
    &--title {
      position: absolute;
      top: 0;
      left: 15px;
      border: 2px solid var(--first-color);
      border-radius: 24px;
      width: 196px;
      padding: 13px 0;
      text-align: center;
      font-size: 16px;
      line-height: 19.36px;
      color: var(--first-color);
      background-color: var(--white-color);
    }
  }

  &__model-body {
    display: flex;
    gap: 12px;
    border-radius: 8px;
    padding: 44px 24px 24px;
    background: #f1f1f1;
    max-width: 100%;
    box-shadow: 0px 0px 1.5px 0px var(--primary-n0);
    @include responsive-13inch {
      flex-direction: column;
    }

    &.download-model {
      display: block;
      padding: 40px 19px 30px;

      & .title {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 22px;
        color: var(--black-3-color);
      }

      & .btn {
        border-radius: 4px;
        font-size: 20px;
        line-height: 24px;

        &:active {
          color: var(--white-color);
        }
      }
    }
  }

  @include responsive-mobile {
    padding: 0;
    &__model-section {
      background: #f1f1f1;
      margin-top: 23px;
      padding: 14px;
      border-radius: 8px;
      box-shadow: 0px 0px 1.5px 0px var(--primary-n0);

      .try-model {
        &__model-content {
          width: 100%;
          height: 100%;
          text-align: center;
          padding: 90px 16px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: var(--black-6-color);
          display: flex;
          flex-direction: column;
          &--title {
            font-size: 16px;
            font-weight: 500;
            line-height: 32px;
          }
        }
      }
    }
    &__model-header {
      margin-bottom: 8px;
      &--title {
        display: inline-block;
        position: relative;
        border: none;
        font-weight: 700;
        line-height: 19px;
        background: none;
        padding: 0;
        width: initial;
        left: 0;
      }
    }
    &__model-title {
      font-size: 14px;
    }
    &__model-body {
      display: flex;
      flex-direction: column;
      padding: 0;
      box-shadow: none;
    }
  }
}
