.edh-image {
  &--loaded {
    animation: image-loaded linear 1s;
  }
}

@keyframes image-loaded {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
