@import 'assets/styles/mixin/responsive.scss';

.edh-forbidden-request-access-modal {
  max-width: 750px;
  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .modal {
    &-content {
      padding: 24px;
    }
    &-header {
      position: relative;
      &__page {
        margin-bottom: 6px;
        font-weight: 700;
        font-size: 11px;
        line-height: 120%;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #00a19c;
      }
      &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #2d2b39;
      }
      .btn-close {
        width: 12px;
        height: 12px;
        padding: 0;
        align-self: flex-start;
      }
    }
    &-body {
      .input-group {
        margin: 0;
        gap: 24px;

        .col {
          padding: 0;
        }
      }
    }
    &-header,
    &-body,
    &-footer {
      padding: 0;
      border: none;
      .btn {
        padding: 8px 20px;
        margin: 0;
      }
    }
  }
}

.edh-toast {
  min-width: 300px;
}
