@import 'assets/styles/mixin/responsive.scss';

.report-form {
  &.hide &__header {
    &-icon {
      transform: none;
    }
    margin-bottom: 8px;
    gap: 0;
  }
  padding: 24px 24px 16px;
  background: var(--white-color);
  border-radius: 4px;
  &__header {
    display: flex;
    gap: 20px 0;
    margin-bottom: 25px;
    justify-content: space-between;
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--black-3-color);
    }
    &-icon {
      width: 18px;
      height: 18px;
      transform: rotate(180deg);
      cursor: pointer;
    }
  }
  &__body {
    .edh-input-description {
      .edh-field__field {
        height: 149px;
      }
      .edh-input--textarea {
        height: 100%;
      }
      .edh-input__max-length {
        bottom: -18px;
      }
    }
    .edh-input--textarea {
      height: 149px;
    }
    .edh-input--text {
      height: 40px;
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: -4px;
  }
  &__details-note {
    font-style: italic;
    font-size: 10px;
    line-height: 12px;
    color: var(--neutral-n700);
  }
  & .edh-select__menu {
    padding: 0;
  }

  @include responsive-mobile {
    padding: 0;
    background: transparent;
    &__header {
      margin-bottom: 16px;
      h3 {
        font-size: 14px;
      }
      &-icon {
        width: 24px;
        height: 24px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
    }
    &__body {
      position: relative;
      .edh-input-description {
        .edh-field__field {
          height: 149px;
        }
        .edh-input--textarea {
          height: 100%;
        }
        .edh-input__max-length {
          bottom: -20px;
        }
      }
      .edh-field {
        &.default {
          .edh-am-dropdown-button__title-group {
            color: var(--placeholder-color);
          }
        }
        &__label {
          line-height: 120%;
          color: var(--neutral-n700);
        }
      }
    }

    &__details-note {
      max-width: calc(100% - 100px);
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      span {
        margin-right: 2px;
      }
    }
    &__footer {
      margin-top: 0;
      flex-direction: column;
      .item__button {
        width: 100%;
        padding: 8px 20px;
      }
    }
  }
}
