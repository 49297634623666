.edh-input {
  $this: &;

  &--text,
  &--textarea {
    position: relative;
    width: 100%;
    padding: 0.625rem 0.5rem;
    background: #ffffff;
    border: 1px solid var(--gray-color3);
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #212121;
    box-sizing: border-box;

    &::placeholder {
      color: var(--gray-color3);
    }
    &:disabled {
      background-color: #fafafa;
      color: var(--gray-color3);
      cursor: not-allowed;
    }
  }

  &--textarea {
    resize: none;
    height: 6.875rem;
  }

  &__max-length {
    font-size: 0.688rem;
    position: absolute;
    bottom: -11px;
    right: 0;
    line-height: 1.2;
    color: #9e9e9e;
    text-transform: lowercase;
  }
}
