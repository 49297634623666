@import 'assets/styles/mixin/responsive.scss';

.edh-confirm-modal {
  .modal-content {
    width: 100%;
    max-width: 568px;
    margin: 0 auto;
  }

  &__content {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--black-3-color);

    margin-bottom: 1.5rem;
  }
  @include responsive-mobile {
    width: 100%;
  }
}
