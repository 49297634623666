@import 'assets/styles/mixin/responsive.scss';

.edh-text-editor {
  width: 100%;
  background: var(--white-color);
  border: 1px solid var(--gray-color3);
  border-radius: 4px;
  position: relative;

  &__emoji {
    position: absolute;
    width: fit-content;
    bottom: 30px;
    right: 0;
    transform: translateX(-25%);
    z-index: 3;
    .emoji-mart-scroll {
      height: 245px;
    }
    &--bg {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  .quill {
    .ql-container {
      border: none;
      height: 120px;
    }

    .ql-mention-list {
      width: auto;
      list-style: none;
      padding: 16px 0;
      overflow: hidden;

      @include responsive-mobile {
        overflow: auto;
      }
    }

    .ql-mention-list::before {
      content: 'SUGGESTION';
      padding: 16px;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #00a19c;
      letter-spacing: 0.1em;
      position: relative;

      @include responsive-mobile {
        padding-right: 0;
      }
    }

    .ql-mention-list-container {
      max-width: 309px;
      max-height: 280px;
      overflow-y: auto;
      border: 1px solid #f0f0f0;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
      z-index: 9001;
    }

    .ql-mention-list-item {
      cursor: pointer;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      min-height: 58px;
      white-space: pre-wrap;
      word-break: break-word;

      & .ql-mention-user {
        &__name {
          margin-bottom: 2px;
          width: 200px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #3f3c4c;

          &.mr {
            width: 150px;
          }
        }
      }
    }

    .ql-mention-list-item.selected {
      background-color: #d3e1eb;
      text-decoration: none;
      @include responsive-mobile {
        background-color: unset;
      }
    }

    .mention {
      color: var(--blue-color-2);
    }

    .ql-editor {
      padding: 0px;
      overflow: hidden;
      overflow-y: scroll;
    }

    > .ql-container > .ql-editor.ql-blank::before {
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: #9f9eae;
      font-style: normal;
      top: 10px;
      left: 8px;

      @include responsive-mobile {
        font-size: 13px;
      }
    }
  }

  &__actions.ql-toolbar {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;
    padding: 4px 8px;
    background: var(--body-color);
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.07);
    border-radius: 0px 0px 4px 4px;
    border: none;
  }

  .ql-snow.ql-toolbar &__action {
    display: flex;
    background-color: transparent;
    border: none;
    width: unset;
    height: unset;

    svg {
      width: 16px;
      height: 16px;
      vertical-align: unset;
    }

    &.primary {
      padding: 8px 12px;
      background-color: var(--first-color);
      border-radius: 4px;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .edh-comment-input__max-length {
    margin-top: 3px;
    position: absolute;
    bottom: -18px;
    text-align: right;
    width: 100%;
    font-size: 11px;
    line-height: 1.2;
    display: block;
    color: #9e9e9e;
  }
}

.edh-loading-balls-mention {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--zindex-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--container-color);
  cursor: wait;

  &--backdrop {
    background-color: #aaaaaa66;
    min-height: 100px;
  }

  &__balls-container {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #424242;
    margin-right: 2rem;
  }

  &__ball {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #7365b1;
    margin-right: 10px;
    animation: ball 0.5s ease infinite alternate;

    &--style-two {
      animation-delay: 0.1s;
    }

    &--style-three {
      animation-delay: 0.2s;
    }
  }

  @keyframes ball {
    to {
      transform: translate(-10px);
    }
  }
}
