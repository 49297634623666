.keyword-skillset-template-table {
  overflow-x: auto;
  font-size: 14px;
  font-weight: 400;
  color: var(--black-4-color, #000);

  div[role='table'] {
    width: fit-content;
  }

  .th,
  .td {
    padding: 0 20px;
  }

  span {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .table-header {
    .tr {
      height: 27px;

      .th:not(:first-child, :nth-child(2)) {
        border-right: 1px solid var(--light-blue-grays-200, #e7edf3);
        background: var(--light-gray-100, #f5f5f5);
      }
    }
  }

  .table-body {
    .tr {
      height: 57px;

      &:first-child .td:not(:first-child, :nth-child(2)) {
        border-right: 1px solid var(--primary-700, #007874);
        background: var(--first-color, #00a19c);

        &:hover,
        &:focus-within {
          border: 2px solid var(--white-color, #fff);
        }

        .edh-table-call-text-editor * {
          font-weight: 600;
          color: var(--white-color, #fff);
        }
      }

      &:nth-child(2n + 1):not(:first-child)
        .td:not(:first-child, :nth-child(2)) {
        border-right: 1px solid var(--light-blue-grays-200, #e7edf3);
        background: var(--light-blue-grays-50, #f9fafb);
      }

      .td:not(:first-child, :nth-child(2)) {
        border-right: 1px solid var(--light-blue-grays-200, #e7edf3);
        background: var(--white-color, #fff);

        &:hover,
        &:focus-within {
          border: 2px solid var(--light-info-400, #3685d3) !important;
        }
      }

      .td:first-child,
      .td:nth-child(2) {
        border-top: 1px solid var(--light-blue-grays-200, #e7edf3);
        background: var(--light-gray-100, #f5f5f5);
      }

      &:not(:first-child) .td:first-child {
        padding: 0;

        .action-column {
          height: 100%;
          display: flex;
          column-gap: 8px;
          align-items: center;
          justify-content: center;

          * {
            cursor: pointer;
          }
        }
      }
    }
  }

  &--view-mode {
    .th:first-child,
    .td:first-child {
      display: none !important;
    }
  }
}
