.edh-ratingstar {
  position: relative;
  width: fit-content;
  &__body {
    display: flex;
    &:hover ~ .tooltip {
      opacity: 1;
    }
    gap: 0 5.5px;
  }

  &__starbox {
    position: relative;
    border: none;
    background: transparent;
  }

  &__starbg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &__starcontainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
}
