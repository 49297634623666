@import 'assets/styles/mixin/responsive.scss';

.edh-am-animated-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .edh-zoombutton {
    display: flex;
    position: absolute;
    bottom: 8px;
    right: 8px;
    height: 2.5rem;
    width: 9.6875rem;
    padding: 0 0.5rem;
    background-color: var(--body-color);
    border: 1px solid rgba(224, 224, 224, 0.7);
    border-radius: 4px;
    z-index: 10;
    @include responsive-mobile {
      display: none;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 0;
      padding: 0;
      width: 100%;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    &__percent {
      font-size: 0.75rem;
      font-weight: 'Inter500';
      line-height: 1.6;
      color: var(--text-color);
    }
  }
}
