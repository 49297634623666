.edh-dropdown-button {
  height: 2.5rem;
  max-width: 9.5rem;
  background: var(--container-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 4px;

  &:hover {
    box-shadow: var(--box-shadow);
  }

  .btn:focus {
    box-shadow: none;
  }

  & .dropdown-toggle:focus {
    box-shadow: none !important;
  }

  &__title-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    color: var(--gray-color3);
    height: var(--h3-font-size);
    width: var(--h3-font-size);
  }

  .dropdown-toggle {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 8px;
    height: 2.5rem;
    max-width: 9.5rem;
    white-space: normal;
    line-height: 1.25;
    color: var(--first-color);
    border: none;
    font-weight: 500;
    font-size: 12px;

    &::after {
      color: var(--first-color);
      font-size: 14px;
      margin-left: 6px;
    }
  }

  .dropdown-toggle.none-title {
    padding: 0 8px;
    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    min-width: 200px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: none;
    padding: 24px 24px 20px 24px;
    z-index: var(--zindex-popover);
  }

  .dropdown-item {
    padding: 0 0 16px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--black-3-color);

    &:hover {
      text-decoration: underline;
      background-color: unset;
    }

    &:last-child {
      padding: 0;
    }

    &.selected-item.dropdown-item {
      color: var(--first-color);
      font-weight: bold;
    }

    &.header-title.dropdown-item {
      font-weight: bold;
      font-size: 11px;
      line-height: 1.2;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: var(--first-color);
      pointer-events: none;
    }

    &:active {
      color: var(--black-3-color);
    }

    &.disabled {
      color: var(--gray-color3);
    }
  }
}
