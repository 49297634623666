.edh-privacy-consent-popup {
  &.modal-dialog-centered {
    max-width: 733px;
  }

  &__content {
    width: 100%;
    padding: 24px;
    font-style: normal;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2d2b39;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #3f3c4c;
  }

  p {
    margin-top: 16px;
  }

  &__button {
    margin-top: 29px;
    display: flex;
    justify-content: center;
  }

  &__button button:first-child {
    margin-right: 60px;
  }
}
