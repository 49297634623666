@import 'assets/styles/mixin/responsive.scss';
.edh-toasts {
  position: fixed;
  bottom: 5px;
  left: calc(100vw - 23px);
  z-index: var(--zindex-alert);
  transform: translateX(-100%);

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-height: 100vh;
    margin: 0;
    padding: 0 16px;
    list-style: none;
    overflow: auto;

    &::-webkit-scrollbar {
      opacity: 0;
      transition: all linear 0.25s;
    }
  }

  a,
  a:visited {
    color: #bee2fe;
    text-decoration: underline;
  }

  @include responsive-mobile {
    max-width: 424px;
    right: 0;
    width: 100%;
    left: unset;
    transform: translateX(0%);

    ul li .edh-toast {
      width: auto;
    }
  }
}
