.edh-am-dropdown-button {
  height: 2.5rem;
  background: var(--container-color);
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--gray-color3);

  &:hover {
    box-shadow: var(--box-shadow);
  }

  & .btn:focus {
    box-shadow: none;
  }

  &__title-group {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  &__icon {
    margin-right: 5px;
    color: #c4c3d1;
    height: var(--h3-font-size);
    width: var(--h3-font-size);
  }

  &__placeholder {
    color: #c9c8d5;
  }

  .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 2px);
    align-items: center;
    padding: 0 0.5rem;
    border: none;

    &::after {
      color: var(--placeholder-color);
      font-size: 14px;
      margin-left: 7px;
    }
  }

  .dropdown-menu {
    width: 100%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    border: none;
    margin-top: 4px;
    padding: 16px 0;
    z-index: var(--zindex-popover);
  }

  .dropdown-item {
    padding: 8px 12px;
    margin: 0 12px;
    width: unset;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #3f3c4c;

    &.selected-item {
      color: var(--first-color);
      font-weight: 700;
    }

    &:hover {
      background-color: transparent;
    }
  }
}
