.edh-btn {
  $primary: #00a19c;
  $white: #ffffff;
  $danger: #e82121;
  $secondary: #9f9eae;
  $secondary-border: #dcdce6;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 7px 19px;

  background-color: transparent;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  border: 1px solid;
  border-radius: 4px;
  transition: all ease-in-out 0.25s;
  white-space: nowrap;
  cursor: pointer;

  &.disabled.contained:disabled,
  &.disabled.outlined:disabled {
    &,
    &:hover {
      cursor: not-allowed;

      color: var(--gray-color);
      background-color: var(--neutral-n200);
      border-color: var(--neutral-n200);

      filter: none;
    }
  }

  &.default {
    border-color: transparent;
    padding: 0 2px;
  }

  &.contained {
    color: $white;
    border-color: currentColor;

    &:hover {
      filter: brightness(110%);
    }

    &.primary {
      background: $primary;
    }

    &.danger {
      background: $danger;
    }

    &.secondary {
      background: $secondary;
    }
  }

  &.outlined {
    background-color: transparent;
    border-color: currentColor;

    &:hover {
      border-color: transparent;
    }

    &.primary {
      color: $primary;

      &:hover {
        color: $white;
        background-color: $primary;
      }
    }

    &.danger {
      color: $danger;

      &:hover {
        color: $white;
        background-color: $danger;
      }
    }

    &.secondary {
      color: $secondary;
      border-color: $secondary-border;

      &:hover {
        color: $white;
        background-color: $secondary;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.loading svg {
    animation: animate-button-rotate ease-in-out infinite 0.25s;
  }
}

@keyframes animate-button-rotate {
  to {
    transform: rotate(360deg);
  }
}
