@import 'assets/styles/mixin/responsive.scss';

div[role='dialog']:has(> .am-purge-modal) {
  z-index: calc(var(--zindex-modal) + 1);
}

.am-purge-modal {
  width: calc(100vw - 16px);

  &__content span {
    white-space: unset;
    font-size: 14px;
  }
  &.modal-dialog {
    max-width: 520px;
  }
  &__content {
    p,
    span {
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 22px;
      color: var(--black-3-color);
    }
  }

  .modal-header {
    align-items: flex-start;
    border-bottom: none;
    padding: 24px 24px 16px 24px;
    @include responsive-mobile {
      padding: 24px 24px 16px 24px;
      .edh-modal-title__value {
        font-size: 20px;
      }
    }
    .btn-close {
      font-size: 12px;
    }
  }

  .modal-body {
    padding: 0 24px;
  }

  .modal-footer {
    display: flex;
    border-top: none;
    padding: 16px 24px 24px 24px;
    .edh-check {
      flex: 1;
      margin: 0;
      &__input {
        width: 22px;
        height: 22px;
      }
      &__label {
        line-height: 24px;
        font-weight: 400;
        font-style: normal;
        color: var(--black-3-color);
      }
    }

    @include responsive-mobile {
      flex-direction: column;
      align-items: flex-end;
      .edh-check {
        margin-bottom: 16px;
      }
    }
    .btn-custom-primary {
      margin: 0;
      background: var(--first-color);
      border-color: var(--first-color);
      line-height: 24px;
      padding: 8px 20px;
    }
    @include responsive-mobile {
      margin-top: 16px;
      padding-top: 0;
      .btn {
        padding: 8px 20px;
      }
    }
  }
}
