.edh-status {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  padding: 2px 4px;
  width: fit-content;

  &--lg {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }

  &-text {
    display: flex;
    align-items: center;
  }
}
