@import 'assets/styles/mixin/responsive.scss';

#edh-notification {
  position: relative;

  @include responsive-mobile {
    &__btn.btn,
    .edh-notification__btn.btn {
      padding: 0 5px;
    }
  }

  &__menus {
    width: 100%;
    max-width: 455px;
    min-width: 250px;
    border: none;

    @include responsive-mobile {
      inset: 0px 5px auto auto !important ;
      max-width: 343px;
    }

    .popover-arrow {
      @include responsive-mobile {
        left: 5px !important;
      }

      &::after,
      &::before {
        border-bottom-color: var(--container-color);
      }
    }

    .card {
      border: unset;
      background: var(--container-color);
      box-shadow: var(--box-shadow);
      border-radius: var(--border-radius);

      @include responsive-mobile {
        .edh-notification-item {
          position: relative;
          padding: 16px 16px 16px 60px;

          &__content {
            width: 237px;
          }

          .edh-notification-item__dot {
            position: absolute;
            left: 16px;
          }
          .edh-avatar--sm {
            position: absolute;
            left: 36px;
          }
          .btn {
            padding: 5px;
          }
        }
      }
    }

    .card-header {
      padding: 24px 24px 24px 16px;
      background-color: unset;
      position: relative;

      button {
        padding: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #00a19c;
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
      }
      .btn:disabled {
        color: #c6c6c6;
        opacity: 1;
      }
    }

    .card-body {
      padding: unset;
      min-height: 50px;

      .edh-message-no-data {
        padding: 120px 50px 90px 50px;
        text-align: center;

        &__description {
          line-height: 24px;
          letter-spacing: unset;
        }
      }
      .edh-notification-item__action {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .card-footer {
      padding: 16px 24px;
      background-color: unset;

      button {
        padding: 0;
        font-weight: bold;
        font-size: 12px;
        line-height: 160%;
        color: #00a19c;
      }
    }
  }

  &__title {
    color: #000000;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &__number-unread {
    margin-left: 8px;
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #8d8d8d;
  }
}
