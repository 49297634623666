@import 'assets/styles/mixin/responsive.scss';

.edh-user-guide-popup {
  &.modal-dialog {
    max-width: 760px;
  }

  &__modal-content {
    padding: 24px;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    button {
      background-color: transparent;
      border: none;

      svg {
        color: var(--gray-color);
      }
    }

    &-time {
      width: 19px;
      height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: 'Roboto';
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;

      color: var(--black-color);

      border: 1px solid var(--black-5-color);
      border-radius: 50%;
    }
  }

  &__btn.btn.btn-custom-primary {
    border-radius: 24px;
    width: fit-content;
    font-size: 20px;
    padding: 8px 16px;
  }

  &__desc {
    padding: 8px 0;

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: var(--black-3-color);
  }

  &__role-guide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
  }

  &__link {
    width: fit-content;
    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 16px;
    line-height: 16px;

    color: var(--first-color);

    &:hover,
    &:link,
    &:visited {
      color: var(--first-color);
    }

    &:not(&:last-child) {
      margin-bottom: 12px;
    }
  }

  &__feedback-btn {
    color: var(--first-color);
    background-color: transparent;
    border: none;
  }

  &__feedback-icon {
    color: var(--first-color);
    font-size: 24px;
  }
}
