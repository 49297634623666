#formattedJson {
  position: relative;
  margin-left: 20px;
  color: #444;
  font: 13px/18px monospace;

  .kvov {
    display: block;
    padding-left: 20px;
  }

  .blockInner {
    display: block;
    border-left: 1px dotted #bbb;
    margin-left: 2px;
  }

  .b {
    font-weight: bold;
  }

  .s {
    color: #0b7500;
    word-wrap: break-word;
    white-space: nowrap;
    span {
      white-space: nowrap;
    }
  }
  a:link,
  a:visited {
    text-decoration: none;
    color: inherit;
  }
  a:hover,
  a:active {
    text-decoration: underline;
    color: #050;
  }
  .bl,
  .nl,
  .n {
    font-weight: bold;
    color: #1a01cc;
  }
  .k {
    color: black;
  }

  [hidden] {
    display: none !important ;
  }
  span {
    white-space: pre-wrap;
  }

  span[line-number]:before {
    content: attr(line-number);
    position: absolute;
    right: calc(100% - 0.6rem);
    margin-top: 0.1rem;
    font-weight: 400;
  }
}
