@import 'assets/styles/mixin/responsive.scss';

.edh-top-navs {
  $margin-left-right: 3rem;

  width: calc(100% - ($margin-left-right * 2));
  min-height: var(--height-topnavs);
  margin: 0 $margin-left-right;
  position: absolute;
  top: 1rem;
  z-index: var(--zindex-fixed);

  &--data-marketplace {
    z-index: var(--zindex-modal-backdrop);
  }

  @include responsive-mobile {
    width: 100%;
    padding: 0 16px;
    margin: 0;
    top: 16px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__left {
    @include responsive-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0 32px;

      .edh-top-navs_logo {
        height: 32px;
      }

      .edh-sidebar-button {
        &.am-theme-page {
          svg {
            color: var(--first-color);
          }
        }
        &__analytics-marketplace-detail svg {
          color: #00a19c;
        }
        &__analytics-marketplace,
        &__data-marketplace {
          svg {
            color: #ffffff;
          }
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;

    @include responsive-mobile {
      & > .btn {
        padding: 0 0.5rem;
      }
    }
  }
}
