@import 'assets/styles/mixin/responsive.scss';

.am-preview-model-result {
  position: relative;
  width: 100%;

  &__title {
    display: flex;
    align-items: baseline;
    gap: 13px;
    .edh-timestamp {
      gap: 3.5px;
      align-items: baseline;
    }
    &-text {
      margin: 15px 0 16px;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
      color: var(--black-3-color);
    }
    @include responsive-mobile {
      flex-direction: column;
      gap: 0;
      &-text {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    margin: 8px 0 24px;
    border-radius: 8px;
    padding: 24px;
    background-color: var(--white-color);
    box-shadow: 0px 0px 1px var(--primary-n0);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      border: 0;
      border-radius: 4px;
      padding: 12px 16px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;

      &:disabled {
        border-color: var(--gray-n200);
        background-color: var(--neutral-n200);
        color: var(--gray-color);
        cursor: not-allowed;
      }

      @include responsive-13inch {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }

    &--primary {
      background-color: var(--first-color);
      color: var(--white-color);
    }

    &__message {
      font-size: 14px;
      line-height: 17px;
      color: #ff0000;
    }
  }

  .edh-react-table {
    &__table {
      &-header {
        width: 100% !important;
        box-shadow: none;
        .tr {
          box-shadow: 0px 3px 3px #ccc;
        }
      }
      &-body {
        width: 100% !important;
      }
      .tr > .th:first-child {
        border-right: none;
        border-left: 1px solid var(--neutral-n200);
        order: 1;
        > div {
          height: 16px;
        }
      }
      .tr > .td:first-child {
        order: 1;
      }
      .tr > .td:nth-child(5) .cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 0 10px;
        .row-actions {
          cursor: pointer;
          min-width: 16px;
        }
      }
    }
  }
}
