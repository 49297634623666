@import 'assets/styles/mixin/responsive.scss';

.edh-footer {
  padding: 3.25rem 3.0625rem 3.25rem 7.5rem;
  background: var(--container-color);

  @include responsive-mobile {
    padding: 47px 6px;
  }

  p {
    width: 100%;

    text-align: right;
    font-weight: 400s;
    font-size: 0.75rem;
    line-height: 1.5;

    color: var(--text-color);

    @include responsive-mobile {
      text-align: center;
    }
  }
}
