.edh-description_editor {
  position: relative;

  .ql-container.ql-snow {
    height: 115px;
  }

  .ql-editor {
    padding-left: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.4;

    &.ql-blank::before {
      color: #9f9eae;
      font-style: normal;
      font-size: 14px;
      left: 10px;
    }

    & li:not(.ql-direction-rtl):before {
      margin-right: 1em;
    }
  }

  .edh-input__max-length {
    bottom: -17px;
  }
}
