@import 'assets/styles/mixin/responsive.scss';

.edh-search-input {
  $this: &;
  position: relative;
  width: 280px;
  height: 36px;
  background: var(--container-color);
  border: 1px solid var(--gray-color3);
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 8px 16px;

  input {
    font-size: 14px;
    font-weight: normal;
    border: none;
    flex: 1;

    &::placeholder {
      font-weight: normal;
      font-size: 14px;
      color: var(--placeholder-color);
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__icon-search,
  &__icon-clear {
    svg {
      cursor: pointer;
    }
  }

  &__count-characters {
    position: absolute;
    bottom: -17px;
    right: 0;
    font-size: 0.688rem;
    line-height: 1.2;
    color: var(--gray-color17);
    text-transform: lowercase;
  }

  @include responsive-mobile {
    padding: 10px 8px 10px 8px;
  }
}
