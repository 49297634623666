@import 'assets/styles/mixin/responsive.scss';

.edh-am-cr-dropdown-button {
  .dropdown-toggle {
    &[aria-expanded='true'],
    &:hover {
      path {
        fill: var(--first-color);
      }
    }
    &:disabled {
      path {
        fill: var(--gray-color3);
      }
    }

    @include responsive-mobile {
      &[aria-expanded='false'] {
        path {
          fill: var(--gray-color3);
        }
      }
    }

    &::after {
      display: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &__header {
    background-color: var(--first-color);
    font-size: 11px;
    line-height: 1.2;
    letter-spacing: 1px;
    color: var(--white-color);
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 18px;
    margin-bottom: 10px;
    padding: 6px 1.5rem;

    @include responsive-mobile {
      padding: 6px 0.5rem;
    }
  }

  & .dropdown-menu {
    inset: unset !important;
    transform: unset !important;
    top: 33px !important;
    right: -150px !important;
    padding: 0px;
    max-height: 710px;
    box-shadow: 0px 8px 1rem rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    border: none;
    min-width: unset;

    @include responsive-mobile {
      right: -110px !important;
      max-height: 800px;
    }
  }

  &__items {
    max-height: 176px;
    overflow-y: auto;
    margin-bottom: 1rem;

    @include responsive-mobile {
      max-height: 208px;
    }
  }

  &__item.dropdown-item {
    font-family: var(--body-font);
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
    color: var(--black-3-color);
    margin-bottom: 1rem;
    padding: 2px 1.5rem;
    height: auto;
    white-space: unset;

    @include responsive-mobile {
      font-size: 15px;
      padding: 2px 16px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &:last-child {
      margin-bottom: unset;
    }

    &:hover {
      text-decoration: none;
      background-color: var(--gray-color12);
    }

    &:active {
      background-color: unset;
    }
  }
}
