.edh-tooltip-hover {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  border: none;
  &.line-1 {
    -webkit-line-clamp: 1;
  }
  &.line-2 {
    -webkit-line-clamp: 2;
  }
  &.line-3 {
    -webkit-line-clamp: 3;
  }
  &.line-4 {
    -webkit-line-clamp: 4;
  }
}
