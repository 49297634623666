@import 'assets/styles/index.scss';
@import 'assets/styles/mixin/responsive.scss';

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

/* Width Scroll */
::-webkit-scrollbar {
  width: 18px;
  height: 18px;
}

/* Track Scroll */
::-webkit-scrollbar-track {
  background: #ffffff;
  @include responsive-mobile {
    background: transparent;
  }
}

/* Handle Scroll */
::-webkit-scrollbar-thumb {
  border: 6px solid transparent;
  background: #dfe1e3;
  border-radius: 11px;
  background-clip: padding-box;

  &:hover {
    border: 6px solid transparent;
    background: #a5a8aa;
    border-radius: 11px;
    background-clip: padding-box;
  }
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: initial;
  }
}

body {
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  overflow: hidden scroll;

  .modal {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .btn-close {
      box-shadow: none;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}
.skeleton {
  background-color: #e2e5e7;
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.5),
    rgba(#fff, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 2s ease infinite;
  &.w-p40 {
    width: 40%;
  }

  &.br-8 {
    border-radius: 8px;
  }
}
@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

.edh-main {
  position: relative;
  min-height: 100vh;
  width: calc(100% - var(--with-sidebar));
  margin-left: auto;
  transition: all 0.5s ease;
  @include responsive-mobile {
    width: 100%;
    transform: unset;
  }
}
