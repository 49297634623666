@import 'assets/styles/mixin/responsive.scss';

.am-tab-history {
  .step-progress {
    --gap: 25px;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    position: relative;
    padding: 2px 20px 2px 34px;
    list-style: none;
    max-height: 660px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    & .step-progress-item {
      position: relative;
      counter-increment: list;
      background: var(--white-color);
      box-shadow: 0px 0px 1px var(--primary-n0);
      border-radius: 8px;
      padding: 24px 16px 24px 25px;

      &.hide .item__description {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        @include responsive-mobile {
          -webkit-line-clamp: 4;
        }
      }

      &.show {
        & .item {
          &__icon {
            transform: rotate(180deg);
          }

          &__title {
            @include responsive-mobile {
              display: block;
            }
          }
        }
      }

      .item {
        &__title {
          display: -webkit-box;
          margin: 0 16px 2px 0;
          width: fit-content;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: var(--black-3-color);
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        &__updated-time {
          display: block;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: var(--gray-color);
          margin-bottom: 4px;
        }
        &__description {
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
          color: var(--black-3-color);
        }
        &__icon {
          position: absolute;
          top: 16px;
          right: 15px;
          cursor: pointer;
        }
        &__progress-icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -32px;
          width: 20px;
          height: 20px;
          padding: 2px;
          border-radius: 50%;
          background-color: var(--white-color);
        }
      }

      &::before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: -23px;
        width: 2px;
        background: var(--neutral-n200);
        height: calc(100% + var(--gap));
      }
      &:first-child::before {
        height: calc(50% + var(--gap));
        top: 50%;
      }
      &:last-child::before {
        height: 50%;
      }
      &:first-child:last-child::before {
        height: 0;
      }
    }
    @include responsive-mobile {
      padding-right: 2px;

      &.step-progress-item {
        padding: 8px;

        & .item {
          &__title {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
