@import 'assets/styles/mixin/responsive.scss';

.introduction-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 11;
  &.visible {
    display: block;
  }
  &__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #e7edf3;
    background: var(--white-color);
    width: 300px;
    .arrow-top {
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
        background: #fff;
        position: absolute;
        z-index: 998;
        left: calc(50% - 5px);
        top: -5px;
      }
    }

    &.bottom {
      top: calc(100% + 15px);
      left: -20%;
    }
    &.top {
      bottom: calc(100% + 15px);
      .arrow-top {
        &:before {
          display: none;
        }
        &:after {
          content: '';
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
          background: #fff;
          position: absolute;
          z-index: 998;
          left: calc(50% - 5px);
          bottom: -5px;
        }
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    width: 100%;
    align-items: center;
    .header-label {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      color: #181818;
    }
    .header-close-button {
      border: none;
      background: none;
      color: #181818;
      cursor: pointer;
    }
  }
  &__description {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #3d3d3d;
    margin-bottom: 24px;
    white-space: normal;
    text-align: left;
  }
  &__footer {
    align-self: flex-end;
    display: flex;
    gap: 16px;
    .edh-btn {
      cursor: pointer;
      min-width: 68px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      padding: 8px 16px;
      flex: 0;
      &.default {
        color: var(--first-color);
      }
    }
  }
  @include responsive-mobile {
    &__container.bottom {
      width: 100%;
      left: 0;
    }
  }
}
