@import 'assets/styles/mixin/responsive.scss';

.try-model {
  flex-direction: column;
  flex-wrap: wrap;
  .am-preview-subtab {
    &__model-panel {
      display: flex;
      gap: 24px;
      @include responsive-13inch {
        flex-wrap: wrap;
      }
      @include responsive-mobile {
        flex-wrap: wrap;
      }
      &-sub {
        flex-grow: 1;
        &:nth-child(1) {
          width: 40%;
        }
        &:nth-child(2) {
          width: 60%;
        }
      }
    }

    &__model-title {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
    }

    &__model-content {
      padding: 16px;
      background: rgba(238, 238, 244, 0.7);
      &:nth-child(2) {
        padding-top: 0;
        height: calc(100% - 46px);
      }
    }
    &__local-files {
      display: flex;

      &__input {
        margin-top: 16px;
        border-radius: 8px;
        max-width: 118px;
        height: 107px;
        background: var(--first-color);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        cursor: pointer;

        &.inActivated {
          background: var(--neutral-n200);
          cursor: not-allowed;
          svg {
            path {
              fill: var(--gray-color);
            }
          }

          p {
            color: var(--gray-color);
          }
        }

        svg {
          display: block;
          margin: 20px auto 8px;
        }

        &--title {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: var(--white-color);
        }

        input[type='file'] {
          display: none;
        }
      }

      &__list {
        display: flex;
        flex: 1;
        width: 0;
        min-height: 154px;
        overflow: hidden;
        overflow-x: auto;
        &::-webkit-scrollbar-thumb {
          border-width: 7px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      }

      &__preview {
        display: flex;
        position: relative;
        gap: 8px;
        padding: 16px 0 11px 8px;

        &--file {
          position: relative;
          border-radius: 8px;
          width: 94px;
          height: 107px;
          padding: 8px;
          background: var(--body-color);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

          &:hover {
            & .am-preview-subtab__local-files__preview--backdrop {
              display: flex;
            }
          }

          canvas {
            height: 91px !important;
          }
        }

        &--file-name {
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }

        &--image {
          width: 100%;
          height: 100%;
        }

        &--backdrop {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(196, 196, 196, 0.4);
          z-index: 1;
        }

        &--trash {
          margin: auto;
          border: 0;
          border-radius: 32px;
          width: 32px;
          height: 32px;
          background-color: var(--first-color);

          img {
            display: block;
            margin: auto;
          }
        }

        &--success {
          position: absolute;
          border-radius: 50%;
          padding: 7px 5px;
          right: calc(50% - 13px);
          bottom: -13px;
          background-color: var(--first-color);
          z-index: 1;
        }
      }
      &__text {
        display: block;
        font-size: 14px;
        line-height: 22px;
        color: var(--black-3-color);
        ol,
        ul {
          padding-left: 1rem;
          margin-bottom: 0%;
        }
        ul > li {
          list-style-type: '-  ';
        }
      }
      &__footer {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        align-items: center;
        margin-top: 16px;
        max-width: 100%;
        &__message {
          font-size: 14px;
          line-height: 17px;
          &.success {
            color: #4caf50;
          }
          &.error {
            color: #ff0000;
          }
        }
        &__run-btn {
          display: flex;
          align-items: center;
          border: 0;
          border-radius: 4px;
          min-width: 132px;
          padding: 8px 12px;
          background: var(--neutral-n200);
          cursor: not-allowed;
          font-size: 16px;

          &:not(:disabled) {
            cursor: pointer;
            background: var(--first-color);
            svg {
              path {
                fill: var(--white-color);
              }
            }
            p {
              color: var(--white-color);
            }
          }
          p {
            margin-left: 10px;
            font-weight: 500;
            line-height: 24px;
            color: var(--gray-color);
          }

          @include responsive-13inch {
            min-width: unset;
            p {
              margin-left: 8px;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
              color: var(--gray-color);
            }
          }
        }
        &__run-btn-wrapper {
          display: inline-block;
          flex-shrink: 0;
          button[disabled] {
            pointer-events: none;
          }
        }
      }
    }
    &__select-submodel {
      .edh-am-dropdown-button {
        border: 1px solid var(--gray-color7);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        height: 40px;
        .dropdown-toggle {
          padding: 10px 14px;
          line-height: 20px;
        }
        .dropdown-menu {
          background: var(--container-color);
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          padding: 12px 0;
          min-width: fit-content;
          .dropdown-item {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: var(--black-3-color);
            padding: 4px 16px;
            margin: 0;
            &:hover {
              text-decoration: none;
            }
          }
        }

        &__title-group {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: var(--black-4-color);
        }
      }
      @include responsive-mobile {
        display: flex;
        overflow: hidden;
        .edh-am-dropdown-button {
          flex: 1;
          position: static;
          overflow: hidden;
          .dropdown-menu {
            width: unset;
          }
          &__title-group {
            min-width: 0;
            flex: 1;
            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    @include responsive-mobile {
      &__local-files__footer {
        flex-wrap: wrap;
        &__message-container {
          flex-shrink: 0;
          width: 100%;
        }
      }
      &__select-submodel {
        flex: 1;
        .edh-am-dropdown-button {
          &__title-group {
            flex: unset;
          }
        }
      }
    }
  }
  .sample-data {
    &__template {
      display: flex;
      align-items: center;
      padding: 12px;
      background-color: var(--container-color);
      border-radius: 4px;
      box-shadow: 0px 0px 1px 0px var(--primary-n0);
      cursor: pointer;

      .file-icon {
        fill: var(--first-color);
        margin-right: 12px;
      }
      .file-info {
        display: flex;
        flex-direction: column;
        .file-name {
          display: inline-flex;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 15.6px;
          color: var(--black-3-color);
          margin-right: auto;
          &:hover {
            text-decoration: underline;
          }
        }
        .file-size {
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 13px;
          color: var(--gray-color);
        }
      }

      &.active {
        border: 1px solid var(--first-color);
      }
      &.disabled {
        cursor: not-allowed;
        .sample-data__field--input {
          color: var(--gray-color);
        }
        .file-icon {
          fill: var(--gray-color);
        }
        .file-name {
          color: var(--gray-color);
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    &__title {
      padding-top: 18px;
      font-size: 14px;
      line-height: 22px;
      color: var(--black-3-color);
    }

    &__group {
      display: flex;
      align-items: center;
      gap: 16px 11px;
      margin-top: 12px;

      input {
        display: none;

        &:checked + div {
          label {
            & #blur {
              display: block;
              border: 2px solid var(--first-color);
              background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(128, 128, 128, 0.1) 100%
              );
            }
          }

          .sample-data__success {
            display: block;
          }
        }

        &:disabled + div {
          label {
            cursor: not-allowed;

            & #blur {
              display: block;
              background: rgba(238, 238, 244, 0.5);
              z-index: 1;
            }
          }
        }
      }

      label {
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        background-color: var(--white-color);
        cursor: pointer;
        min-width: 131px;
        width: 131px;
        height: 153px;
        display: flex;
        justify-content: center;
        align-items: center;

        & .react-pdf {
          &__Page {
            canvas {
              max-height: 152px;
            }
          }
        }
      }
      &.full-width {
        display: flex !important;
        flex-direction: column !important;
        gap: 8px;
        .sample-data {
          &__template {
            width: 100%;
            flex-grow: 1;
            .file-container {
              width: 100%;
              display: flex;
              align-items: center;
            }
            .file-info {
              flex-grow: 1;
            }
          }
          &__success {
            position: relative;
            bottom: unset;
            right: unset;
          }
        }
      }
    }

    &__card {
      position: relative;
      & #blur {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 8px;
      }
    }

    &__zoom-in {
      position: absolute;
      bottom: 12px;
      left: 7px;
      border: none;
      border-radius: 25px;
      width: 25px;
      height: 25px;
      background-color: var(--first-color);

      img {
        display: block;
        margin: 0 2.5px 3px;
      }
    }

    &__result {
      padding-top: 19px;
      color: var(--first-color);
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
    }

    &__form {
      padding-top: 15px;
    }

    &__success {
      display: none;
      position: absolute;
      border-radius: 50%;
      padding: 7px 5px;
      right: calc(50% - 13px);
      bottom: -13px;
      background-color: var(--first-color);
      z-index: 1;
    }
  }
  .template-layout {
    .am-preview-subtab {
      &__model-panel-sub {
        &:nth-child(2) {
          width: 60%;
          min-width: 60%;
        }
      }
      &__model-title {
        margin-bottom: 16px;
      }
      &__model-content {
        padding: 16px;
        .sample-data {
          &__label {
            margin-bottom: 8px;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 15.6px;
            color: var(--neutral-n700);
          }
          &__card {
            display: flex;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
          }
          &__title {
            padding-top: 0;
          }
          &__field {
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            &--input {
              padding: 10px 8px;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 19.6px;
              color: var(--black-3-color);
              background-color: var(--neutral-n200);
              border: 1px solid var(--gray-color3);
              border-radius: 4px;
              resize: none;

              &::placeholder {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 19.6px;
                color: var(--black-3-color);
              }
              &.textarea {
                min-height: 150px;
                max-height: 207px;
                overflow-x: auto;
                &::-webkit-scrollbar-track {
                  background-color: var(--neutral-n200);
                }
              }
            }
          }
          &__group {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 16px;
          }
        }
      }
      &__local-files {
        flex-wrap: wrap;
        &__list {
          min-height: 140px;
        }
        &__preview {
          padding-top: 8px;
        }
      }
      &__local-files__text.requirement {
        margin-bottom: 16px;
      }
      &__local-files__input {
        margin-top: 8px;
      }
      &__local-template {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: var(--black-3-color);
        &__label {
          cursor: pointer;
          font-weight: 500;
          color: var(--first-color);
          &.disabled {
            cursor: not-allowed;
            color: var(--gray-color);
          }
        }
      }
      &__local-form {
        padding: 0;
      }
      &__local-upload-requirement {
        display: flex;
        flex-direction: column;
        min-width: 100%;
      }
    }
    .edh-field:not(:only-child) {
      .edh-field__field {
        margin-bottom: 16px;
      }
    }
    .edh-field__label.upload-label {
      margin-bottom: 0;
    }
    .edh-field__sub-label {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.2px;
      color: var(--black-6-color);
    }
    .edh-input {
      &:disabled {
        background-color: var(--neutral-n200);
      }
      &::placeholder {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px;
        color: var(--gray-color);
      }
    }
    .edh-field__message {
      display: none;
    }
    .edh-input__max-length {
      display: none;
    }
  }

  @include responsive-mobile {
    .am-preview-subtab {
      &__model-panel {
        width: 100%;

        &-sub {
          max-width: 100%;
        }
        .sample-data__group {
          overflow: auto hidden;
          padding-bottom: 14px;
          label {
            min-width: unset;
            width: 119px;
            max-width: 119px;
          }
        }
      }
      &__local-files__footer__run-btn {
        min-width: fit-content;
        height: fit-content;
        padding: 8px 12px;
        p {
          font-size: 16px;
        }
        svg {
          transform: scale(calc(17 / 20));
        }
      }
    }
    .template-layout {
      .upload-label {
        display: inline;
      }
      .am-preview-subtab {
        &__model-content {
          .sample-data {
            &__group {
              gap: 16px 8px;
              overflow-x: hidden;
              margin-bottom: 0;
              grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            }
            &__field.skill-set {
              margin-bottom: 0;
            }
            &__card {
              width: 100%;
            }
          }
        }
        &__model-panel {
          .sample-data {
            &__group {
              .label {
                flex-shrink: 1;
              }
            }
          }
        }
      }
    }
  }
}

.sample-data {
  &__modal {
    .modal-content {
      padding: 24px;
      width: fit-content;
    }
    &__content {
      overflow: hidden;
    }
    .modal-dialog {
      justify-content: center;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 6px 19px 0;

      &__title {
        font-size: 11px;
        font-weight: 700;
        line-height: 13.2px;
        letter-spacing: 1px;
        color: var(--first-color);
        text-transform: uppercase;
      }

      &__close {
        color: var(--gray-color);
        cursor: pointer;
        flex-shrink: 0;
      }
    }

    &__body {
      border-radius: 8px;
      padding: 19px 19px 73px;
      background-color: var(--body-color);
      position: relative;

      @include responsive-mobile {
        padding: 0;
        overflow: hidden;
      }

      &.sample-data__modal__preview {
        overflow: hidden;
      }
      .output-pagination {
        display: flex;
        align-items: center;
        gap: 8px;
        position: absolute;
        left: 68px;
        bottom: 26px;
        background: var(--white-color);
        &__previous,
        &__next {
          display: block;
          border: none;
          border-radius: 4px;
          width: 36px;
          height: 36px;
          background: var(--first-color);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

          &:disabled {
            background: var(--gray-color3);
            opacity: 0.2;
            cursor: not-allowed;
          }

          svg {
            display: block;
            margin: auto;
          }
        }

        &__previous {
          svg {
            transform: rotate(-180deg);
          }
        }

        &__input {
          border: 1px solid var(--gray-color3);
          border-radius: 4px;
          width: 36px;
          height: 36px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
          font-size: 14px;
          line-height: 19.6px;
          color: var(--black-3-color);
          text-align: center;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &[type='number'] {
            -moz-appearance: textfield;
          }
        }

        &__page {
          font-size: 14px;
          line-height: 22px;
          color: var(--black-6-color);
        }
      }
      .edh-am-animated-container {
        position: unset;
        .edh-zoombutton {
          right: 19px;
          bottom: 24px;
        }
      }
    }
  }
  &__zoom-out {
    position: absolute;
    left: 24px;
    bottom: 28px;
    border: none;
    border-radius: 32px;
    padding-bottom: 2px;
    width: 32px;
    height: 32px;
    background-color: var(--first-color);
  }
  &__preview-image {
    height: 100%;
  }
}
