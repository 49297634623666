.edh-searchbar-keywords-suggest-option {
  font-size: 15px;
  color: var(--black-3-color);
  padding: 0.5rem 0.75rem;
  cursor: pointer;

  .edh-highlighter {
    background-color: transparent;
    font-weight: bold;
    padding: 0;
  }
}
