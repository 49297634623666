.edh-forbidden-alert {
  position: relative;
  border: 1px solid var(--light-info-500, #005cb9);
  border-left: 4px solid var(--light-info-500, #005cb9);
  background: var(--light-info-100, #e1f2fe);
  padding: 20px 44px;
  font-size: 14px;
  color: var(--black-4-color, #000000);

  .alert-icon {
    position: absolute;
    top: 20px;
    left: 12px;

    path {
      fill: var(--light-info-500, #005cb9);
    }
  }

  .alert-header {
    margin: 0;
    color: var(--light-info-700, #00488f);
    letter-spacing: 0.25px;
  }

  .alert-title {
    display: block;
    margin-top: 8px;
  }

  .alert-content:nth-of-type(1) {
    margin-top: 12px;
    margin-bottom: 20px;
  }
}
