@import 'assets/styles/mixin/responsive.scss';

.edh-search-bar-with-suggestion {
  position: relative;
  width: 100%;

  @include responsive-mobile {
    position: unset;
  }

  &__search {
    background: var(--white-color);
    height: 38px;

    form {
      height: inherit;
      .edh-search-input {
        padding: 2px 8px;
        border-right: inherit;
        border: 1px solid var(--gray-color3);
        border-radius: 4px;
        height: inherit;
        width: 100%;

        input {
          width: 95%;
        }
      }
    }
  }

  .edh-search-input {
    border: 1px solid var(--gray-color3);
    border-radius: 4px;
    box-shadow: none;
  }

  &__dropdown {
    position: absolute;
    top: 47px;
    width: 100%;
    min-height: 60px;
    z-index: 1035;
    background: var(--white-color);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in;
    max-height: 250px;
    border: 1px solid var(--gray-color3);

    @include responsive-mobile {
      padding-bottom: 0rem;
      top: 90;
    }

    &--header-title {
      color: var(--first-color);
      padding: 1.5rem 1.5rem 0rem 1.5rem;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1px;
      cursor: default;
    }

    &--menu-list {
      max-height: 200px;
      overflow-y: scroll;
      margin: 0rem 0.75rem;
      color: var(--black-3-color);
      font-size: 15px;
      letter-spacing: 1px;
      line-height: 1.6;
    }

    &--show {
      opacity: 1;
      pointer-events: auto;
    }

    &--no-options {
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text-color-light);
    }
  }

  .edh-loading-balls {
    border-radius: 10px;
  }
}
