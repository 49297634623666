.edh-timestamp {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--gray-color);

  &__icon {
    display: flex;
  }
}
