.edh-am-tags {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  &__list {
    max-height: 18px;
    display: flex;
    width: fit-content;
    .edh-status--sm:not(:first-child) {
      margin-left: 4px;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    span {
      white-space: nowrap;
    }
  }
  &__number {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-left: 4px;

    .edh-status--tag:hover {
      font-weight: 500;
    }
  }
  &__number-tooltip {
    max-width: 398px;

    .popover-arrow::after {
      border-top-color: var(--black-4-color);
    }
  }

  &__number-tooltip-body {
    background: var(--black-4-color);
    border-radius: 4px;
    padding: 12px;

    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-height: 274px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background: unset;
    }

    &::-webkit-scrollbar-thumb {
      border: 7px solid transparent;
      background: var(--black-3-color);
      border-radius: 2px;
      background-clip: padding-box;
    }
  }

  .hidden {
    visibility: hidden;
  }
}
